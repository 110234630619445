import React from 'react';
import { css } from 'linaria';
import createClassNames from '../Theme/Utils/createClassNames';
import { theme } from '../Theme';

const productSpecificationsCSS = css`
  &__list {
    display: table;
  }

  &__list-item {
    display: table-row;
  }

  &__list-item-title,
  &__list-item-value {
    display: table-cell;
  }

  &__list-item-title {
    padding-right: ${theme.space[4]};
    font-weight: ${theme.fontWeights.semibold};
  }
`;

export const excludedSpecificationCustomFields = [
  'VariantTypeLabel',
  'VariantProducts',
  'size-guide'
];

export const getSpecifcations = customFields => {
  return customFields?.reduce(
    (acc, { key, title, stringValue, listValues }) => [
      ...acc,
      ...(!excludedSpecificationCustomFields.includes(key)
        ? stringValue
          ? [{ title, stringValue }]
          : listValues?.length > 0
          ? [{ title, stringValue: listValues.join(', ') }]
          : []
        : [])
    ],
    []
  );
};

const baseCSS = productSpecificationsCSS;

const ProductSpecifications = ({ className, specifications }) => {
  if (!specifications) return null;

  return (
    <>
      <ul className={createClassNames([baseCSS, className], '__list')}>
        {specifications.map(({ key, title, stringValue }) => (
          <li
            key={key}
            className={createClassNames([baseCSS, className], '__list-item')}
          >
            <span
              className={createClassNames(
                [baseCSS, className],
                '__list-item-title'
              )}
            >
              {title}
            </span>
            <span
              className={createClassNames(
                [baseCSS, className],
                '__list-item-value'
              )}
            >
              {stringValue}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ProductSpecifications;
