import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';

const stockColors = {
  loading: theme.colors.grey300,
  inStock: '#27AE60',
  outOfStock: '#EB5757',
  notifyWhenBack: '#F2C94C'
};

const stockOrbStyles = css`
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  transition: background 0.2s linear;
  margin-right: 0.5rem;
  border: 1px solid transparent;

  &.inStock {
    background-color: ${stockColors['inStock']};
  }

  &.outOfStock {
    background-color: ${stockColors['outOfStock']};
  }

  &.notifyWhenBack {
    background-color: ${stockColors['notifyWhenBack']};
  }

  &.missingVariant {
    background-color: transparent;
    border-color: ${stockColors['outOfStock']};
  }

  &.loading {
    background-color: ${stockColors['loading']};
  }
`;

const StockOrb = ({ status }) => {
  return <span className={cx(stockOrbStyles, status ? status : null)} />;
};

export default StockOrb;
