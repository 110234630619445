import React from 'react';
import { css, cx } from 'linaria';
import { default as UIBreadcrumbs } from '@jetshop/ui/Breadcrumbs';
import { theme } from '../../Theme';

export const breadCrumbsCSS = css`
  display: flex;
  flex-wrap: nowrap;
  width: 70%;

  && {
    margin-bottom: ${theme.space[2]};

    li {
      color: hsl(0deg 0% 50%);
      font-size: 10px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    a {
      text-decoration: none;
      color: ${theme.colors.black};
      font-size: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  ${theme.above.md} {
    width: 100%;

    && {
      li,
      a {
        font-size: 12px;
      }
    }
  }
`;

const Breadcrumbs = props => {
  const { className, ...breadcrumbProps } = props;

  return (
    <UIBreadcrumbs
      {...breadcrumbProps}
      className={cx('breadcrumbs', breadCrumbsCSS, className)}
    />
  );
};

export default Breadcrumbs;
