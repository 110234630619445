import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';
import ProductSlider from '../ProductSlider/ProductSlider';
import { containerClass } from '../ThemeClasses';
import HelloRetailFetcher from './HelloRetail';

const helloRetailSectionCSS = css`
  width: 100%;
  margin-top: ${theme.space[2]};

  &__section + &__section {
    margin-top: ${theme.space[3]};
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: ${theme.space[3]};
  }

  ${theme.above.md} {
    &__title {
      font-size: 2rem;
    }

    &__section + &__section {
      margin-top: ${theme.space[6]};
    }
  }
`;

const baseCSS = helloRetailSectionCSS;

const HelloRetailSection = ({
  helloRetailBoxes,
  customHierarchies = null,
  slidesToShowXL = 5
}) => {
  // const isCarousel = helloRetailBox.products.length > 4;
  const boxKeyLabels = helloRetailBoxes?.map(box => box?.key);

  return boxKeyLabels?.length > 0 ? (
    <HelloRetailFetcher
      boxKeyLabels={boxKeyLabels}
      // customPathname={customPathname}
      customHierarchies={customHierarchies}
      // customUrls={customUrls}
    >
      {({ boxes, loading }) => {
        if (loading) return null;

        return (
          <HelloRetailBoxRender
            helloRetailBoxes={helloRetailBoxes}
            boxes={boxes}
            slidesToShowXL={slidesToShowXL}
          />
        );
      }}
    </HelloRetailFetcher>
  ) : null;
};

export const HelloRetailBoxRender = ({
  helloRetailBoxes,
  boxes,
  loggedIn,
  className,
  slidesToShowXL = 5
}) => {
  if (boxes && typeof boxes === 'object' && Object.entries(boxes)?.length > 0) {
    return (
      <div className={cx(baseCSS, 'hello-retail-container', className)}>
        {helloRetailBoxes.map((box, i) => {
          return boxes[box.key]?.length > 0 ? (
            <div className={`${baseCSS}__section`} key={i}>
              {box?.title && (
                <div className={containerClass}>
                  <h2 className={`${baseCSS}__title`}>{box.title}</h2>
                </div>
              )}

              <ProductSlider
                products={boxes[box.key]}
                imageSizes={[1 / 3, 1 / 3, 1 / 6]}
                loggedIn={loggedIn}
                slidesToShowXL={slidesToShowXL}
              />
            </div>
          ) : null;
        })}
      </div>
    );
  }

  return null;
};

export default HelloRetailSection;
