export const isExternalLink = url => {
  const localDomains = [
    'localhost:3000',
    'jaktogfriluft.jetshop.se',
    'www.jaktogfriluft.no'
  ];

  const match = url.match(/https?:\/\//);

  if (!match) {
    return false;
  }

  const link = new URL(url);
  if (localDomains.indexOf(link.host) >= 0) {
    return false;
  }

  return true;
};
