// const hexToHSL = require('./components/Theme/Utils/hexToHSL');

/* Theme colors variants */
const colorVariants = {
  grey200: '#F2F2F2',
  grey300: '#F7F7F7',
  grey350: '#DEDEDE',
  grey400: '#BCBCBC',
  grey600: '#6A6A6A',
  grey800: '#414141',
  grey900: '#292929',
  green400: '#457E50',
  green600: '#2E6037',
  orange600: '#E0853C',
  orange800: '#D37A32',
  yellow600: '#FFCE00',
  red600: '#DB2548'
};

/* Jetshop Flight default colors */
const colors = {
  white: '#FFFFFF',
  black: '#000000',
  main: '#000000',
  grey: '#878787',
  darkerGrey: '#767676',
  lightgrey: '#E8E8E8',
  tablegrey: '#F3F3F3',
  beige: '#f5f5dc',
  loadingBar: '#2f80ed',
  blue: '#146DE1',
  red: '#EB0000',
  background: '#f7f7f7',
  darkgrey: '#333',
  mediumgrey: '#9a9a9a',
  highlight: '#2f80ed',
  ...colorVariants
};

/* Theme colors */
const themeColors = {
  primary: colors.green600,
  secondary: colors.orange600,
  yellow: colors.yellow600,
  red: colors.red600,
  bodyBackground: colors.grey200,
  logoGrey: '#757474',
  fontGrey: colors.grey600,
  fontDisableGrey: colors.grey400,
  inputHoverGrey: colors.grey300
};

const button = {
  primary: {
    backgroundDefault: themeColors.primary,
    borderDefault: themeColors.primary,
    colorDefault: colors.white,
    backgroundHover: colors.green400,
    borderHover: colors.green400,
    colorHover: colors.white
  },
  secondary: {
    backgroundDefault: colors.orange600,
    borderDefault: colors.orange600,
    colorDefault: colors.white,
    backgroundHover: colors.orange800,
    borderHover: colors.orange800,
    colorHover: colors.white
  },
  tertiary: {
    backgroundDefault: colors.black,
    borderDefault: colors.black,
    colorDefault: colors.white,
    backgroundHover: colors.grey900,
    borderHover: colors.grey900,
    colorHover: colors.white
  },
  quaternary: {
    backgroundDefault: colors.white,
    borderDefault: colors.white,
    colorDefault: colors.black,
    backgroundHover: colors.grey300,
    borderHover: colors.grey300,
    colorHover: colors.grey800
  },
  quintary: {
    backgroundDefault: 'transparent',
    borderDefault: colors.grey600,
    colorDefault: colors.black,
    backgroundHover: colors.grey300,
    borderHover: colors.black,
    colorHover: colors.black
  },
  senary: {
    backgroundDefault: colors.grey300,
    borderDefault: colors.grey300,
    colorDefault: colors.black,
    backgroundHover: colors.grey350,
    borderHover: colors.grey350,
    colorHover: colors.black
  }
};

const topBar = {
  background: themeColors.primary,
  item: colors.white
};

const header = {
  background: themeColors.bodyBackground,
  backgroundScrolled: themeColors.bodyBackground,
  item: colors.black,
  itemScrolled: colors.black,
  logoFillStartpage: colors.white,
  logoFillScrolled: themeColors.logoGrey
};

const menu = {
  background: colors.white,
  subLevelBackground: colors.white,
  item: colors.black,
  itemBackgroundActive: colors.grey200
};

const cart = {
  badgeBackground: colors.black,
  badgeText: colors.white
};

const main = {
  background: themeColors.bodyBackground
};

const footer = {
  background: themeColors.bodyBackground,
  text: colors.black
};

const elementColors = {
  topBar,
  header,
  menu,
  cart,
  main,
  footer,
  button
};

const themeFontSizes = {
  s: '.8rem',
  base: '1rem',
  xl: '1.25rem',
  '2xl': '1.563rem',
  '3xl': '1.953rem',
  '4xl': '2.441rem',
  '5xl': '3.052rem',
  '6xl': '3.814rem',
  '7xl': '4.768rem'
};

const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];

const breakpoints = {
  xs: '20rem',
  sm: '40rem',
  md: '50rem',
  lg: '64rem',
  lgl: '75rem',
  xl: '80.5rem'
};

const commonStyles = {
  borderRadius: '8px',
  boxShadow: '0 12px 24px rgb(0 0 0 / 5%)',
  maxWidth: breakpoints.xl
};

const commonStylesInt = {
  maxWidth: Math.ceil(parseFloat(commonStyles.maxWidth) * 16)
};

const elementSizesInt = {
  tapTarget: 48,
  topBarHeight: 32
};

const elementSizes = {
  buttonHeight: '48px',
  edgePaddingSizeMu: space[4],
  edgePaddingSizeMd: space[1],
  pagePaddingTop: space[2],
  pagePaddingBottom: space[4],
  flyoutEdgePadding: space[2],
  headerHeightDesktop: '87px',
  headerHeightMobile: '56px',
  tapTarget: `${elementSizesInt.tapTarget}px`,
  topBarHeight: `${elementSizesInt.topBarHeight}px`
};

const transitions = {
  background: 'background ease-in-out .2s',
  border: 'border ease-in-out .2s',
  boxShadow: 'box-shadow ease-in-out .2s',
  color: 'color ease-in-out .2s',
  flyout: 'transform cubic-bezier(0.22, 0.35, 0.05, 1) .4s',
  opacity: 'opacity ease-in-out .2s',
  imageScale: 'transform ease-in-out .2s',
  positionBase: 'ease-in-out .2s'
};

const categoryIds = {
  campaign: 1,
  jakt: 152,
  klaer: 153,
  friluftsliv: 154,
  fiske: 155,
  finnNO: 68,
  brands: 428,
  outlet: 840,
  blackFriday: 849
};

const pageIds = {
  start: 44,
  magazine: 55
};

const pageRoutes = {
  magazine: '/blogg'
};

const contentManagerTags = {
  settings: 'settings'
};

const contentManagerIds = {
  settings: '7acd2d32-8e99-492d-9ce5-4260962671ca'
};

const lipscoreAPIKey = 'daac3c5097fd788d7db1bd57';

module.exports = {
  default: {
    storeName: 'Jakt & friluft',
    productsPerPage: 24,
    colors: {
      ...colors,
      ...colorVariants,
      ...themeColors,
      ...elementColors
    },
    themeFontSizes,
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints,
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: space,
    fonts: {
      primary: 'Switzer'
    },
    elementSizesInt,
    elementSizes,
    commonStylesInt,
    commonStyles,
    transitions,
    categoryIds,
    pageIds,
    pageRoutes,
    contentManagerTags,
    contentManagerIds,
    lipscoreAPIKey
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
