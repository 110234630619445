import React from 'react';
import { css, cx } from 'linaria';
import { ReactComponent as CheckIcon } from '../../../svg/Check.svg';
import { ReactComponent as HeartIcon } from '../../../svg/Heart.svg';
import { theme } from '../../Theme';
import usePageSettings from '../Hooks/usePageSettings';

export const USPListCSS = css`
  &__list-item {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: ${theme.space[1]};
  }
`;

const base = USPListCSS;

export const USPItems = [
  { text: 'Incididunt ut labore', icon: 'check' },
  { text: 'Et dolore magna', icon: 'check' },
  { text: 'Ad minim veniam quis', icon: 'check' }
];

export const iconSelector = icon =>
  ({
    check: CheckIcon,
    heart: HeartIcon
  }[icon] || React.Fragment);

export const createUSPItem = ({ text, icon }, listElement = 'li') =>
  React.createElement(
    listElement,
    {
      className: cx('usp-item', `${base}__list-item`),
      key: text
    },
    <>
      {icon
        ? React.createElement(iconSelector(icon), {
            className: cx('usp-icon', `${base}__icon`)
          })
        : null}
      {text}
    </>
  );

const USP = ({ wrapperElement = 'ul', listElement = 'li' }) => {
  const settings = usePageSettings();
  const settingsUSPItems = settings?.headerUsp?.length
    ? settings.headerUsp.map(item => {
        return { text: item, icon: 'check' };
      })
    : null;

  return (
    <>
      {settingsUSPItems?.length > 0 &&
        React.createElement(
          wrapperElement,
          { className: cx('usp-wrapper', base) },
          <>{settingsUSPItems.map(usp => createUSPItem(usp, listElement))}</>
        )}
    </>
  );
};

export default USP;
