import React from 'react';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { theme } from '../../Theme';
import { lipscoreMinHeightPxInt } from './LipscoreRating';

const LipscoreRatingLoadingState = () => {
  const backgroundColor = theme.colors.grey300;

  return (
    <LoadingLine
      color={backgroundColor}
      widthRem={9.5}
      heightPx={lipscoreMinHeightPxInt}
    />
  );
};

export default LipscoreRatingLoadingState;
