export const checkPropValue = prop => {
  return typeof prop !== 'object'
    ? prop
    : prop?.value?.hasOwnProperty('value')
    ? prop.value.value
    : prop?.hasOwnProperty('value')
    ? prop.value
    : undefined;
};

const setFieldValues = (acc, prop) => {
  return {
    ...acc,
    [prop[0]]: checkPropValue(prop[1])
  };
};

const getPropsOrFieldValues = props =>
  typeof props === 'object' && Object.entries(props).reduce(setFieldValues, {});

export default getPropsOrFieldValues;
