import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as CrossIcon } from '@jetshop/ui/svg/Cross.svg';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './queries/removeFromCart.gql';
import setCartItemQuantity from './queries/setCartItemQuantity.gql';
import cartQuery from './queries/cartQuery.gql';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.space[3]};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: ${theme.space[2]};
  }
`;

const ProductName = styled('div')`
  a {
    text-decoration: none;
  }
  .attribute {
    display: block;
    font-size: 12px;
    margin-top: 4px;
  }
  h2 {
    font-size: 0.875rem;
    font-weight: ${theme.fontWeights.regular};
  }
`;

const ProductDetail = styled('section')`
  width: 100%;
  display: grid;
  height: auto;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  grid-area: bottomright;

  text-align: right;
`;

const AdjustQty = styled('div')`
  display: flex;
  grid-area: bottomleft;
  margin-left: 14px;
  /* Plus/minus buttons */
  button {
    background: ${theme.colors.white};
    color: black;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.bodyBackground};
  }
  svg {
    height: 10px;
    width: 10px;
    stroke: ${theme.colors.black};
  }
`;

const AdjustQtyAndPriceDiv = css`
  display: flex;
  width: 100%;
  margin-top: 14px;
  flex-wrap: nowrap;
  justify-content: space-between;
`;
const ImageAndProductNameDiv = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  > div {
    flex: 1 1 35%;
    max-width: 91px;
    margin-right: ${theme.space[2]};

    img {
      background: ${theme.colors.white};
      max-height: 84px;
      max-width: 91px;
    }
  }
`;

const CartItem = ({ item, cartId, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const distributionPackageSize = item?.product?.distributionPackageSize ?? 1;

  const minimumQuantity = distributionPackageSize
    ? Number(distributionPackageSize)
    : 1;

  const [setQuantity] = useMutation(setCartItemQuantity, {});

  const customSetQuantity = quantityMutationArgs => {
    return () => {
      setQuantity({
        variables: {
          input: {
            ...quantityMutationArgs
          }
        }
      });
    };
  };

  // const { incrementQuantity } = useIncrementQuantity({
  //   incrementQuantityMutation,
  //   cartQuery
  // });

  // const { decrementQuantity } = useDecrementQuantity({
  //   decrementQuantityMutation,
  //   cartQuery
  // });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const incrementQuantity = customSetQuantity({
    itemId: item.id,
    quantity: item.quantity + minimumQuantity,
    cartId
  });

  const decrementQuantity = customSetQuantity({
    itemId: item.id,
    quantity: item.quantity - minimumQuantity,
    cartId
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <Wrapper className={className}>
      <div className={ImageAndProductNameDiv}>
        <div>
          {item.product.images.length > 0 && (
            <Image
              aspect="13:12"
              sizes="5rem"
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
              quality={80}
            />
          )}
        </div>
        <ProductDetail>
          <ItemDetails>
            <FlyoutTrigger id="cart-flyout">
              {flyout => (
                <ProductName onClick={flyout.hideTarget}>
                  <Link to={item.product.primaryRoute.path}>
                    <h2 data-testid="item-name">{item.product.name}</h2>
                    {isVariant && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {variantNamesValues.map(
                          (variantOptionNameValue, index) => (
                            <li className="attribute" key={index}>
                              {variantOptionNameValue}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    {item.configurations.length > 0 && (
                      <ul
                        style={{
                          marginTop: '0'
                        }}
                      >
                        {item.configurations.map(
                          ({ option: { name } }, index) => (
                            <li className="attribute" key={index}>
                              {name}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </Link>
                </ProductName>
              )}
            </FlyoutTrigger>
          </ItemDetails>

          <RemoveItem>
            <button
              onClick={() =>
                removeFromCart({ itemId: item.id, product: item.product })
              }
              data-testid="remove-from-cart"
            >
              <CrossIcon />
            </button>
          </RemoveItem>
        </ProductDetail>
      </div>
      <div className={AdjustQtyAndPriceDiv}>
        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === minimumQuantity}
            onClick={() =>
              item.quantity !== minimumQuantity &&
              decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />
      </div>
    </Wrapper>
  );
};

export default CartItem;
