import { styled } from 'linaria/react';
import { theme } from '../../Theme';

export default styled('main')`
  flex: 1 1 auto;
  padding-top: ${theme.elementSizes.pagePaddingTop};
  padding-bottom: ${theme.elementSizes.pagePaddingBottom};
  background: ${theme.colors.main.background};
  margin-top: ${theme.elementSizes.headerHeightMobile};

  ${theme.above.sm} {
    margin-top: ${theme.elementSizes.headerHeightDesktop};
  }

  .start & {
    margin-top: 0;
  }
`;
