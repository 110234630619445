import React from 'react';
import { theme } from '../../Theme';

const PopulateProductsWithLipscore = ({ children, products }) => {
  const apiKey = theme?.lipscoreAPIKey;

  const [productData, setProductData] = React.useState(products);
  const [hasFetched, setHasFetched] = React.useState(false);

  const requestString = React.useMemo(() => {
    return Object.values(productData).reduce(
      (acc, product) => acc + `&internal_id[]=${product.articleNumber}`,
      ''
    );
  }, [productData]);

  React.useEffect(() => {
    setProductData(products);
    setHasFetched(false);
  }, [products]);

  React.useEffect(() => {
    if (!apiKey || hasFetched) return;

    if (requestString) {
      fetch(
        'https://api.lipscore.com/products?api_key=' +
          apiKey +
          requestString +
          '&fields=rating,votes'
      )
        .then(response => response?.json())
        .then(response => {
          if (response?.length > 0) {
            const productsWithLipscoreData = productData.map(product => {
              const responseProduct = response.find(
                responseProduct =>
                  String(
                    responseProduct?.sku?.[0]
                      ? responseProduct.sku[0]
                      : responseProduct?.internal_id
                  ) === product.articleNumber
              );

              return responseProduct
                ? {
                    ...product,
                    lipscore: {
                      rating: responseProduct.rating,
                      votes: responseProduct.votes
                    }
                  }
                : product;
            });

            setProductData(productsWithLipscoreData);
            setHasFetched(true);
          }
        })
        .catch(error => {
          console.log('Error fetching lipscore data: ', error);
        });
    }
  }, [apiKey, requestString, productData, hasFetched]);

  return hasFetched ? children(productData) : children(products);
};

export default PopulateProductsWithLipscore;
