import React, { useState } from 'react';
import { cx, css } from 'linaria';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { ReactComponent as Check } from '../../svg/Check.svg';
import { sharedStyles, dropdownStyles } from './VariantSelector';

const gitCardSelectorCSS = css`
  .gift-card-dropdowns {
    display: flex;
    justify-content: space-between;

    > div {
      width: 47%;
      margin-top: 0;
    }
  }
`;

export function VariantSelectorGiftCard({
  product,
  variantHandler,
  showValidation
}) {
  const [selectedLowValue, setSelectedLowValue] = useState(null);
  const [selectedHighValue, setSelectedHighValue] = useState(null);

  return (
    <div className={cx(sharedStyles, gitCardSelectorCSS)}>
      {product.variants.options.map((option, index) => {
        let showValidationMessage = false;

        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        return (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            variantHandler={variantHandler}
            showValidationMessage={showValidationMessage}
            doNotDisable={index === 0}
            selectedLowValue={selectedLowValue}
            setSelectedLowValue={setSelectedLowValue}
            selectedHighValue={selectedHighValue}
            setSelectedHighValue={setSelectedHighValue}
          />
        );
      })}
    </div>
  );
}

function DropdownVariantSelect({
  option,
  variantHandler,
  showValidationMessage,
  selectedLowValue,
  setSelectedLowValue,
  selectedHighValue,
  setSelectedHighValue
}) {
  const { selectValue } = variantHandler;

  const sortedValues = option.values
    .slice()
    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

  const lowerValues = sortedValues.filter(value => parseInt(value, 10) <= 1000);
  const higherValues = sortedValues.filter(value => parseInt(value, 10) > 1000);

  const handleLowValueSelect = value => {
    setSelectedLowValue(value);
    setSelectedHighValue(null);
    selectValue(value, option);
  };

  const handleHighValueSelect = value => {
    setSelectedHighValue(value);
    setSelectedLowValue(null);
    selectValue(value, option);
  };

  const renderDropdown = (values, label, handleSelect, selectedValue) => (
    <div className={dropdownStyles}>
      <DropdownMenu>
        <DropdownMenuButton id={`option-${option.name}-${label}`}>
          {selectedValue ? selectedValue : <span>{label}</span>}
          <Caret />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
          {values.map(value => {
            const active = selectedValue === value;

            return (
              <DropdownMenuItem
                data-testid={value + option.name}
                key={value + option.name}
                className={cx(active && 'active')}
                onSelect={({ setIsOpen }) => {
                  handleSelect(value);
                  setIsOpen(false);
                }}
              >
                <span> {value}</span>
                {selectedValue === value && <Check />}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );

  return (
    <div className={cx(showValidationMessage && 'invalid')}>
      <label htmlFor={`option-${option.name}`}>{option.name}</label>
      <div className={'gift-card-dropdowns'}>
        {renderDropdown(
          lowerValues,
          '50-1000 kr',
          handleLowValueSelect,
          selectedLowValue
        )}
        {renderDropdown(
          higherValues,
          '1000 kr +',
          handleHighValueSelect,
          selectedHighValue
        )}
      </div>
    </div>
  );
}

export default VariantSelectorGiftCard;
