import React from 'react';
import { css, cx } from 'linaria';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';
import { theme } from '../Theme';

const favourite = css`
  button {
    background: transparent;
    color: ${theme.colors.black};

    svg {
      &.active {
        path {
          fill: currentColor;
          stroke: currentColor;
        }
      }

      path {
        &:hover {
          fill: ${theme.colors.grey350};
          stroke: currentColor;
        }
      }
    }
  }
`;

export function Favourite({
  className,
  product,
  label,
  variant,
  listId,
  ...rest
}) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList(listId);

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product.name
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <div className={cx('favourite-button-holder', favourite)} {...rest}>
      <button
        className={className}
        onClick={toggleWithoutBubble}
        aria-label="Add to favourites"
      >
        <HeartSVG
          className={isInList ? 'active' : 'inactive'}
          style={{ height: '1em', width: '1em' }}
        />
        {label && <span>{t('Favourite')}</span>}
      </button>
    </div>
  );
}
