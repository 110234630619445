import useCommonPageData from './useCommonPageData';

const groupFooterLinks = data => {
  // Group links
  for (let key in data) {
    const match = key.match(/^footerLinkGroup(\d?)LinkTitle$/);
    if (!match) {
      continue;
    }

    if (!Array.isArray(data[key])) {
      continue;
    }

    const linkGroup = {
      title: data[`footerLinkGroup${match[1]}Title`],
      level: data[`footerLinkGroup${match[1]}Level`],
      links: []
    };
    data[key].forEach((title, index) => {
      if (title) {
        linkGroup.links.push({
          title,
          link: data[`footerLinkGroup${match[1]}Link`][index]
        });
      }
    });

    if (!Array.isArray(data.footerLinkGroups)) {
      data.footerLinkGroups = [];
    }

    data.footerLinkGroups.push(linkGroup);
  }

  // Remove old data
  for (let key in data) {
    const match = key.match(/^footerLinkGroup[^s]/);
    if (match) {
      delete data[key];
    }
  }

  return data;
};

const groupLinks = data => {
  const groups = [
    {
      titles: 'menuLinkTitle',
      links: 'menuLink',
      map: 'menuLinks'
    },
    {
      titles: 'flyoutMenuLinkTitle',
      links: 'flyoutMenuLink',
      map: 'flyoutMenuLinks'
    },
    {
      titles: 'headerLinkTitle',
      links: 'headerLink',
      map: 'headerLinks'
    },
    {
      titles: 'campaignLinkTitle',
      links: 'campaignLink',
      map: 'campaignLinks'
    }
  ];

  groups.forEach(group => {
    if (data[group.titles]) {
      const links = [];

      if (
        typeof data[group.titles] === 'string' &&
        typeof data[group.links] === 'string'
      ) {
        data[group.titles] = [data[group.titles]];
        data[group.links] = [data[group.links]];
      }

      for (let index in data[group.titles]) {
        if (data[group.titles][index]) {
          links.push({
            title: data[group.titles][index],
            link: data[group.links][index]
          });
        }
      }

      data[group.map] = links;

      delete data[group.titles];
      delete data[group.links];
    }
  });

  return data;
};

const groupData = data => {
  let parsedData = {};

  for (let key in data) {
    const parsedKey = key.replace(/\d$/, '');

    if (!data[key]) {
      continue;
    }

    if (!parsedData[parsedKey]) {
      parsedData[parsedKey] = data[key];
    } else {
      if (!Array.isArray(parsedData[parsedKey])) {
        parsedData[parsedKey] = [parsedData[parsedKey]];
      }
      parsedData[parsedKey].push(data[key]);
    }
  }

  parsedData = groupLinks(parsedData);
  parsedData = groupFooterLinks(parsedData);

  return parsedData;
};

const usePageSettings = () => {
  const data = useCommonPageData();
  const settings = data?.settings;

  if (!settings) {
    return false;
  }

  return groupData(settings);
};

export default usePageSettings;
