import { theme } from '../../Theme';
import { checkPropValue } from '../Utils/getPropsOrFieldValues';
import useGetTagData from './useGetContentManagerData';

const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const parseData = data => {
  let structuredData = {};

  data.properties.forEach(item => {
    structuredData[item.name] = checkPropValue(item);
  });

  data.children.forEach(item => {
    if (!structuredData.hasOwnProperty(item.type)) {
      structuredData[item.type] = [];
    }

    let props = {};
    item.properties.forEach(item => {
      props[item.name] = checkPropValue(item);
    });

    structuredData[item.type].push(props);
  });

  return structuredData;
};

const useCommonPageData = () => {
  const data = useGetTagData({ ids: [theme.contentManagerIds.settings] });
  const groups = data?.content?.content?.[0]?.items;

  if (!groups) {
    return false;
  }

  return groups.reduce((data, item) => {
    const type = camelize(item.type);
    if (typeof data[type] === 'undefined') {
      data[type] = parseData(item);
    } else {
      if (!Array.isArray(data[type])) {
        data[type] = [data[type]];
      }
      data[type].push(parseData(item));
    }
    return data;
  }, []);
};

export default useCommonPageData;
