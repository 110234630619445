import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';
import { pseudoCaretCSS } from '../../Theme/ThemeClasses';

// background: ${theme.colors.white};
// border: 1px solid ${theme.colors.grey400};

export const sharedQuantityCSS = `
  border-radius: 8px;
  border: 1px solid ${theme.colors.grey600};
  background: transparent;
  text-align: center;
  font-weight: ${theme.fontWeights.semibold};
  font-size: 1rem;
`;

const qtyWrapper = css`
  display: flex;
  align-items: center;
  height: 48px;

  &__inner {
    position: relative;
    margin-right: ${theme.space[1]};
  }

  select {
    min-width: 72px;
    height: 48px;
    opacity: 0;
    cursor: pointer;
  }

  &__custom-select {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 4px;
    padding-left: 4px;
    margin-bottom: 0px;
    outline: none;
    pointer-events: none;
    ${sharedQuantityCSS}
  }

  &__caret {
    position: relative;
    top: 1px;
    margin-left: ${theme.space[1]};
    transform: rotate(90deg);
  }
`;

const Quantity = ({
  quantity,
  setQuantity,
  minimumQuantity,
  field,
  className,
  disabled
}) => {
  const handleQuantityChange = e => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(field.name, newQuantity);
  };

  const quantityOptions = new Array(10)
    .fill(minimumQuantity)
    .map((minimumQuantity, i) => minimumQuantity * (i + 1));

  return (
    <div className={cx(qtyWrapper, className)}>
      <div className={`${qtyWrapper}__inner`}>
        <select
          name="quantity"
          value={quantity}
          onChange={handleQuantityChange}
          disabled={disabled}
          {...field}
        >
          {quantityOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className={`${qtyWrapper}__custom-select`}>
          {quantity}
          <span className={cx(`${qtyWrapper}__caret`, pseudoCaretCSS)} />
        </div>
      </div>
    </div>
  );
};

export default Quantity;
