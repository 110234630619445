import { styled } from 'linaria/react';
import { theme } from '../Theme';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 ${theme.elementSizes.edgePaddingSizeMd};
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${theme.commonStyles.maxWidth};

  ${theme.above.md} {
    padding: 0 ${theme.elementSizes.edgePaddingSizeMu};
  }
`;

export default MaxWidth;
