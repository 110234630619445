import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';

const common = `
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
  color: #000000;
`;

const UntilLimit = styled('div')`
  ${common};

  [data-flight-price] {
    display: inline;

    > * {
      display: inline;
    }
  }

  .price {
    font-weight: ${theme.fontWeights.semibold};
  }
`;

const Reached = styled('div')`
  ${common};
  p {
    font-size: 12px;
    margin-right: 3px;

    &:first-child {
      font-weight: ${theme.fontWeights.semibold};
    }
  }
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit)
    return (
      <UntilLimit key="until-limit">
        {t.rich(`Remaining until free shipping: {price}`, {
          price: <Price key="price" price={untilLimit} />
        })}
      </UntilLimit>
    );

  return (
    <Reached {...rest}>
      <p>{t('Delivery')}</p>
      <p>{t('Free for your order')}</p>
    </Reached>
  );
}

export default FreeShipping;
