import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import { Link } from 'react-router-dom';
// import { Below } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import CategoryLink from '@jetshop/ui/CategoryLink';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { theme } from '../../Theme';
import { pseudoCaretCSS } from '../../Theme/ThemeClasses';
import categoryIconMap from './categoryIconMap';
// import HighlightedLinks from './HighlightedLinks';
// import PageLinks from './PageLinks';
import FlyoutMenuLinks from './FlyoutMenuLinks';
import CampaignLinks from './CampaignLinks';
import usePageSettings from '../../Theme/Hooks/usePageSettings';

const menuInnerPadding = '2rem';

const menuCSS = css`
  /* margin-top: ${theme.space[2]}; */
  justify-content: space-between;
  padding-bottom: 6rem;
  /* overflow-x: hidden; */
  background: ${theme.colors.menu.background};
  color: ${theme.colors.menu.item};

  &,
  ul {
    display: flex;
    flex-direction: column;
  }

  &__highlighted-links {
    margin-bottom: ${theme.space[2]};
  }

  &__page-list-item {
    padding-left: ${menuInnerPadding};
  }

  &__page-list-item + &__page-list-item {
    padding-top: ${theme.space[2]};
  }

  &__page-link {
    color: currentColor;
  }

  &__flyout-menu-links {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: ${theme.space[4]} 0;

    li {
      &:first-child {
        padding-top: ${theme.space[4]};
        border-top: 1px solid ${theme.colors.fontDisableGrey};
      }
    }

    a {
      color: ${theme.colors.black};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__campaigns-menu-links {


    .campaigns-list-item {
      &:first-child {

     a {
      text-decoration: none;
     }
  
      }

      &:last-child {
        font-size: 0.75rem;
      }
    }
   
    &:hover {
    background: ${theme.colors.orange800};
  } 
 
    
  }

/* &__campaigns-links {
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.semibold};
  transition: ${theme.transitions.background};
  &:hover {
    background: ${theme.colors.orange800};
  } 
}

&__campaigns-link-1 {
  text-decoration: none;
  padding:  19px 0;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem ${menuInnerPadding};
  transition: ${theme.transitions.positionBase};
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }

 svg {
  transform: rotate(-90deg) scale(0.9);
  margin-right: -4px;


  path {
    stroke: ${theme.colors.white};
  }
 }
}

&__campaigns-link-2 {
font-size: 12px;
padding-left: ${menuInnerPadding};
padding-bottom: ${theme.space[3]};

transition: ${theme.transitions.positionBase};
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }

} */

  &__finn-link {
    width: 100%;

    a {
    padding:  19px 0;
    padding-left: 20px;
    transition: ${theme.transitions.background};
    cursor: pointer;
      text-decoration: none;
    }

    &:hover {
    background: ${theme.colors.menu.itemBackgroundActive};
  } 
}
`;

const subCategoryCSS = css`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  overflow-y: auto;
  background: ${theme.colors.menu.subLevelBackground};
  transition: transform cubic-bezier(0, 0.2, 1, 0.8) 0.125s;
  will-change: transform;
  pointer-events: none;
  z-index: 1;

  &--open {
    transform: translateX(0%);
    overflow: hidden;
    pointer-events: auto;
  }

  &__inner {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    li:first-child {
      a {
        text-decoration: underline;
      }
    }
  }
`;

const menuItemCSS = css`
  display: inline-block;
  width: 100%;
  padding: 1.2rem ${menuInnerPadding};
  position: relative;
  background: transparent;
  font-size: 1rem;
  text-align: left;
  text-decoration: none;
  color: currentColor;
  transition: background ease-in-out 0.2s;

  &:hover {
    background: ${theme.colors.menu.itemBackgroundActive};
  }

  /* &.active,
  &:hover,
  &:focus {
    background: ${theme.colors.menu.itemBackgroundActive};
  } */

  &__label {
    &:after {
      position: absolute;
      top: 50%;
      right: 2em;
      transform: translateY(-50%);
    }
  }

  &--back-button {
    span {
      font-size: 1.25rem;
      font-weight: ${theme.fontWeights.semibold};
      margin-left: ${theme.space[4]};
    }
    &:after {
      position: absolute;
      top: 50%;
      left: 2em;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__icon-wrapper-inner {
    margin-right: ${theme.space[2]};
  }
`;

const menuWrapperCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
`;

const CategoryIcon = ({ categoryId, ...rest }) => {
  const Icon = categoryIconMap(categoryId);

  return Icon ? <Icon {...rest} /> : null;
};

const MaybeWithIcon = ({ children, useIcon = false, categoryId }) => {
  return useIcon ? (
    <span className={`${menuItemCSS}__icon-wrapper`}>
      <span className={`${menuItemCSS}__icon-wrapper-inner`}>
        <CategoryIcon
          className={`${menuItemCSS}__icon`}
          categoryId={categoryId}
        />
      </span>
      {children}
    </span>
  ) : (
    children
  );
};

const subLevelCategoryLabelIntl = 'Category menu {categoryName}';

const SubLevelEntry = ({ category, closeMenu, useIcon = false }) => {
  const t = useIntl();
  const subLevelState = React.useState(false);
  const matches = useRouteMatch(category.primaryRoute.path);

  return (
    <>
      <button
        className={cx(
          menuItemCSS,
          matches || subLevelState[0] ? 'active' : null
        )}
        aria-controls={t(subLevelCategoryLabelIntl, {
          categoryName: category.name
        })}
        aria-expanded={subLevelState[0]}
        onClick={() => subLevelState[1](!subLevelState[0])}
      >
        <MaybeWithIcon useIcon={useIcon} categoryId={category.id}>
          <span className={cx(`${menuItemCSS}__label`, pseudoCaretCSS)}>
            {category.name}
          </span>
        </MaybeWithIcon>
      </button>
      <SubLevelCategory
        category={category}
        closeMenu={closeMenu}
        subLevelState={subLevelState}
        useIcon={false}
      />
    </>
  );
};

const SubLevelCategory = ({ category, subLevelState, closeMenu }) => {
  const t = useIntl();

  return (
    <div
      className={cx(
        subCategoryCSS,
        subLevelState[0] ? `${subCategoryCSS}--open` : null
      )}
      aria-label={t(subLevelCategoryLabelIntl, { categoryName: category.name })}
      aria-hidden={!subLevelState[0]}
    >
      <div className={`${subCategoryCSS}__inner`}>
        <button
          className={cx(
            menuItemCSS,
            `${menuItemCSS}--back-button`,
            pseudoCaretCSS
          )}
          onClick={() => subLevelState[1](!subLevelState[0])}
          aria-label={t('Go to previous menu level')}
        >
          <span>{category.name}</span>
        </button>
        <ul>
          <li>
            <CategoryLink
              className={menuItemCSS}
              onClick={closeMenu}
              category={category}
            >
              {t('Everything in {categoryName}', {
                categoryName: category.name
              })}
            </CategoryLink>
          </li>
          {category?.subcategories?.map(category => (
            <li key={category.id}>
              <MenuItem
                key={category.id}
                category={category}
                closeMenu={closeMenu}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuItem = ({ category, closeMenu, useIcon = false }) => (
  <>
    {category?.hasSubcategories && category.id !== theme.categoryIds.brands ? (
      <SubLevelEntry
        category={category}
        closeMenu={closeMenu}
        useIcon={useIcon}
      >
        <MaybeWithIcon useIcon={useIcon} categoryId={category.id}>
          {category.name}
        </MaybeWithIcon>
      </SubLevelEntry>
    ) : (
      <CategoryLink
        className={menuItemCSS}
        onClick={closeMenu}
        category={category}
      >
        <MaybeWithIcon useIcon={useIcon} categoryId={category.id}>
          {category.name}
        </MaybeWithIcon>
      </CategoryLink>
    )}
  </>
);

const CategoryTree = ({ closeMenu }) => {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 2
    }
  });

  const topLevelCategories = result?.data?.categories.filter(
    category => category.level === 1
  );

  return topLevelCategories ? (
    <ul>
      {topLevelCategories.map(category => (
        <li key={category.id}>
          <MenuItem category={category} closeMenu={closeMenu} useIcon />
        </li>
      ))}
    </ul>
  ) : null;
};

const displayMenuTimeout = 400; // Drawer animation + 100ms

const handleCloseMenu = (closeDrawer, setDisplayMenu) => {
  closeDrawer();
  setTimeout(() => {
    setDisplayMenu(false);
  }, displayMenuTimeout);
};

const Menu = useIcon => {
  const [displayMenu, setDisplayMenu] = React.useState(true);

  // const [customerServicePages, businessPages] = useGetPageLinks([
  //   [47],
  //   [47, 51]
  // ]);

  React.useEffect(() => {
    if (displayMenu === false) {
      setTimeout(() => {
        setDisplayMenu(true);
      }, displayMenuTimeout);
    }
  }, [displayMenu]);

  const settings = usePageSettings();
  const campaignLinks = settings.campaignLinks;

  return !displayMenu ? null : (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <div className={menuWrapperCSS}>
            <div className={menuCSS}>
              {campaignLinks && (
                <CampaignLinks
                  className={`${menuCSS}__campaigns-menu-links`}
                  liClass={cx(
                    `${menuCSS}__page-list-item`,
                    'campaigns-list-item'
                  )}
                  linkClass={cx(`${menuCSS}__page-link`, 'campaigns-page-link')}
                />
              )}

              <CategoryTree
                closeMenu={() =>
                  handleCloseMenu(drawer.hideTarget, setDisplayMenu)
                }
              />
              <ul className={`${menuCSS}__finn-link`}>
                <Link
                  onClick={() =>
                    handleCloseMenu(drawer.hideTarget, setDisplayMenu)
                  }
                  to="/finn"
                >
                  <MaybeWithIcon useIcon={useIcon} categoryId={68}>
                    Kupp på Finn.no
                  </MaybeWithIcon>
                </Link>
              </ul>
            </div>
            <FlyoutMenuLinks
              className={`${menuCSS}__flyout-menu-links`}
              liClass={`${menuCSS}__page-list-item`}
              linkClass={`${menuCSS}__page-link`}
            />
          </div>
        </Drawer>
      )}
    </DrawerTarget>
  );
};

export default Menu;
