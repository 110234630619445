import React, { useContext } from 'react';
import loadable from '@loadable/component';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import t, { useIntl } from '@jetshop/intl';
import ProductConfigurationContext from '@jetshop/core/components/ProductConfigurationProvider/ProductConfigurationContext';
import { useVariantFromUrl } from '@jetshop/core/hooks/useProductVariants/useVariantFromUrl';
import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { Above, Below, Between } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { Price } from '@jetshop/ui/Price';
// import { ReactComponent as Compare } from '../../svg/Compare.svg';
import MaxWidth from '../Layout/MaxWidth';
import { Favourite } from '../ProductList/Favourite';
import Spinner from '../ui/Spinner';
import { buttonBaseCSS, buttonQuintary } from '../ui/Button';
import Breadcrumbs from '../Theme/Breadcrumbs';
import Videoly from '../Theme/Videoly';
import USP from '../Theme/USP';
import { LipscoreRating, useLipscoreLoader } from '../Theme/Lipscore';
import { theme } from '../Theme';
import AddToCartForm from './AddToCart/AddToCartForm';
import ImageContainer from './Images/ImageContainer';
import ProductInfoAccordion from './ProductInfoAccordion';
import ProductPageLoadingState from './ProductPageLoadingState';
import { PriceHistoryWrapper } from './PriceHistory';
import { RelatedProducts } from './RelatedProducts';
import { Container } from './styledComponents';
import { ProductSection } from './ProductInfo';
import ProductTabs from './ProductTabs';
import { sharedQuantityCSS } from './AddToCart/Quantity';
import { badgeTemplatesCSS } from '../CategoryPage/ProductCard';
import HelloRetailFetcher, {
  helloRetailBoxes
} from '../Theme/HelloRetail/HelloRetail';
import { HelloRetailBoxRender } from '../Theme/HelloRetail/HelloRetailSection';

const LoadablePackageProduct = loadable(
  () => import('./PackageProduct/PackageProduct'),
  {
    fallback: <Spinner />
  }
);

const ProductContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;

  header {
    margin-top: ${theme.space[4]};
    h1 {
      font-size: 1.25rem;
      line-height: 1.25;
      margin-top: 6px;
    }
    h2 {
      font-size: 1rem;
      font-weight: ${theme.fontWeights.regular};
      color: ${theme.colors.fontGrey};
    }
  }

  .product-brand {
    color: ${theme.colors.fontGrey};
  }

  .product-sku {
    margin-top: ${theme.space[1]};
    color: ${theme.colors.fontGrey};
  }

  /* .customer-comment-input-wrapper {
    width: 100%;
    .customer-comment-input {
      height: 44px;
    }
    .status-wrapper {
      top: -39px;
    }
  } */

  .quantity-input-wrapper {
    margin-right: ${theme.space[1]};
    margin-bottom: 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      border-radius: ${theme.commonStyles.borderRadius};
      -moz-appearance: textfield; /* Firefox */
    }

    input {
      width: ${theme.elementSizes.buttonHeight};
      height: ${theme.elementSizes.buttonHeight};
      padding: 0px;
      margin-bottom: 0px;
      ${sharedQuantityCSS}
    }

    label {
      text-align: center;
    }
  }

  .short-description {
    margin-top: ${theme.space[2]};
    line-height: 1.5;
    font-size: 16px;
    color: #707070;
    p {
      margin-bottom: 1rem;
    }
  }

  .delivery-list {
    li {
      display: flex;
      align-items: center;
      svg {
        margin-right: 16px;
      }
      margin-bottom: 16px;
    }
  }

  .price-wrapper {
    margin-top: ${theme.space[2]};
  }

  .product-details {
    ${theme.above.md} {
      margin-top: ${theme.space[1]};
      align-items: flex-start;
    }

    .price,
    .new-price,
    .old-price {
      font-weight: ${theme.fontWeights.semibold};
      display: inline-block;
    }
    .price,
    .new-price {
      font-size: 1.25rem;
    }
    .new-price {
      margin-right: 8px;
      color: ${theme.colors.red};
    }
    .old-price {
      color: #707070;
    }
  }

  .button-wrapper {
    display: flex;
    margin-top: ${theme.space[2]};
    align-items: center;
    justify-content: space-between;

    .compare-button,
    .favourite-button-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 49%;
    }

    // Temp CSS when not using compare product button
    .favourite-button-holder {
      flex: 0 0 100%;
    }

    .compare-button,
    .favourite-button {
      width: 100%;
      font-size: 15px;
      font-weight: ${theme.fontWeights.regular};
      padding: 0;
    }

    .compare-button {
      svg {
        margin-left: -8px;
        height: 11px;
        width: 19px;
      }
    }
    .favourite-button {
      div {
        z-index: 2;
      }
      &.button {
        padding-top: 0.9rem;
        margin-top: -0.9rem;
        padding-bottom: 0.9rem;
        margin-bottom: -0.9rem;
        padding-left: 4rem;
        margin-left: -4rem;
        padding-right: 6.7rem;
        margin-right: -6.7rem;
      }

      &:hover {
        svg {
          path {
            fill: ${theme.colors.grey350};
          }
        }
      }

      svg {
        margin-left: -8px;
      }
    }
  }

  .usp-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.space[2]};

    .usp-item {
      flex: 0 0 50%;
      font-size: 12px;
      margin-top: ${theme.space[1]};
    }
  }

  .image-gallery {
    .badge-top-left {
      top: 0;
      color: ${theme.colors.white};
      font-size: 1rem;
      width: auto;
      text-transform: uppercase;
      font-weight: ${theme.fontWeights.semibold};

      > div {
        margin-top: ${theme.space[1]};

        &:first-child {
          margin-top: 0;
        }
      }

      span {
        padding: ${theme.space[2]};
      }

      .new {
        background: ${theme.colors.orange600};
      }

      .discount {
        background: ${theme.colors.red600};
      }
    }
  }

  .related-variants-name {
    font-size: 1rem;
  }

  ${theme.above.md} {
    .smaller-width-wrapper {
      width: 85%;
    }

    header {
      margin-top: ${theme.space[1]};
      h1 {
        font-size: 1.625rem;
      }
    }

    .usp-wrapper {
      margin-top: ${theme.space[1]};
    }
  }

  .hello-retail-container {
    margin-bottom: 0;
  }

  &.is-gift-card {
    .product-sku,
    .price-wrapper,
    .lipscore-container,
    .stock-status-wrapper {
      display: none;
    }

    .tab-buttons {
      button {
        &:last-child {
          display: none;
        }
      }
    }
  }
`;

const ProductPageLipscoreRating = ({ product, lipscoreNavigationRef }) => {
  const { lipscoreRef } = useLipscoreLoader();

  return (
    <LipscoreRating
      product={product}
      lipscoreRef={lipscoreRef}
      lipscoreNavigationRef={lipscoreNavigationRef}
    />
  );
};

export const isGiftCard = product => {
  const customFields = product?.customFields || [];

  const gavekortField = customFields.find(
    field => field.key === 'Gavekort' && field.type === 'BOOL'
  );

  return gavekortField?.boolValue === true;
};

const Product = ({
  result: { data, loading },
  product,
  variant,
  partialProduct
}) => {
  const initialVariant = useVariantFromUrl();
  const intl = useIntl();
  const configurationContext = useContext(ProductConfigurationContext);
  const price = useDynamicPrice(product, variant);
  const variantHandler = useProductVariants(product, {
    initialVariant,
    preselectCheapest: false
  });

  const lipscoreNavigationRef = React.useRef(null);

  const {
    selectedVariant: selectedVariation,
    getMissingOptions
  } = variantHandler;

  React.useEffect(() => {
    if (product && typeof window !== 'undefined') {
      document.documentElement.scrollTop = 0;
    }
  }, [product]);

  if (!product) {
    product = partialProduct;
  }
  // Return early if there is no product
  if (!product) {
    return (
      <ProductContainer>
        <ProductPageLoadingState />
      </ProductContainer>
    );
  }

  const images = product.images ?? [];
  const parents = getParentOrCanonicalRoute(data?.route?.parents, product);
  const relatedProducts = product.relatedProducts;
  const hasDiscount = product?.price?.incVat < product?.previousPrice?.incVat;

  let badges = [...(product.badges || [])];
  product.isPackage &&
    badges.push({
      location: 'TOP_LEFT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  if (hasDiscount) {
    badges.unshift({
      location: 'TOP_LEFT',
      name: 'Discount',
      text:
        (
          ((product.previousPrice.incVat - product.price.incVat) /
            product.previousPrice.incVat) *
          100
        ).toFixed(0) + '%',
      style: 'discount'
    });
  }

  const brandCategory = product.categories?.find(
    cat => cat?.parent?.id === theme.categoryIds.brands
  );

  const customHierarchy =
    data?.route?.breadcrumbs?.length > 0 ? [...data.route.breadcrumbs] : [];

  const customPathname = product?.primaryRoute?.path;

  const helloRetailProductPageBoxKeys = [
    helloRetailBoxes.ALTERNATIVES,
    helloRetailBoxes.PRODUCTS_PURCHASED_TOGETHER
  ];

  const helloRetailSectionAlternative = [
    {
      key: helloRetailBoxes.ALTERNATIVES,
      title: intl('Related products')
    }
  ];

  const helloRetailSectionPurchasedTogether = [
    {
      key: helloRetailBoxes.PRODUCTS_PURCHASED_TOGETHER,
      title: intl('Popular products')
    }
  ];

  const isGiftCardProduct = isGiftCard(product);

  return (
    <ProductContainer className={isGiftCardProduct ? 'is-gift-card' : ''}>
      <MaxWidth>
        <Breadcrumbs
          breadcrumbs={data?.route?.breadcrumbs || []}
          parents={parents}
        />
      </MaxWidth>
      <Container>
        <ProductSection>
          {images && (
            <ImageContainer
              className={badgeTemplatesCSS}
              images={images}
              variant={selectedVariation}
              badges={badges}
            />
          )}
          <Above breakpoint="lgl">
            {matches =>
              matches && (
                <ProductTabs
                  lipscoreNavigationRef={lipscoreNavigationRef}
                  product={product}
                />
              )
            }
          </Above>
          <Between breakpoints={['md', 'lgl']}>
            {matches =>
              matches && (
                <>
                  <ProductInfoAccordion
                    product={product}
                    lipscoreNavigationRef={lipscoreNavigationRef}
                  />
                </>
              )
            }
          </Between>
        </ProductSection>
        <ProductSection>
          <div className="product-details">
            <header>
              {brandCategory && (
                <div>
                  <CategoryLink
                    className={'product-brand'}
                    category={brandCategory}
                  />
                </div>
              )}
              <h2>{product.subName}</h2>
              <h1 data-testid="product-title">
                {product.name || 'Loading...'}
              </h1>
              <div className={'product-sku'}>
                Art.nr: {product.articleNumber}
              </div>
            </header>
            <div className={'price-wrapper'}>
              <Price
                hidePrice={product.hidePrice}
                {...price}
                css={{
                  marginBottom: '24px',
                  opacity:
                    configurationContext && configurationContext.loading
                      ? 0.3
                      : 1
                }}
              />
              <PriceHistoryWrapper
                articleNumber={product.articleNumber}
                hasVariants={product?.hasVariants}
                variant={selectedVariation}
              />
            </div>
            <ProductPageLipscoreRating
              product={selectedVariation || product}
              lipscoreNavigationRef={lipscoreNavigationRef}
            />
            <div
              className="short-description"
              dangerouslySetInnerHTML={{
                __html: product.shortDescription
              }}
            />
            <Videoly product={product} />
            <div className={'smaller-width-wrapper'}>
              {product?.isPackage ? (
                <LoadablePackageProduct product={product} />
              ) : (
                <AddToCartForm
                  product={product}
                  variant={selectedVariation}
                  getMissingOptions={getMissingOptions}
                  variantHandler={variantHandler}
                />
              )}
              <div className={'button-wrapper'}>
                {/* <Button className={cx('compare-button', buttonQuintary)}>
                  <Compare />
                  <span>{t('Compare')}</span>
                </Button> */}
                <Favourite
                  className={cx(
                    'favourite-button',
                    buttonBaseCSS,
                    buttonQuintary
                  )}
                  label
                  product={product}
                  variant={selectedVariation}
                />
              </div>

              <USP />
            </div>
            <Below breakpoint="md">
              {matches =>
                matches && (
                  <>
                    <ProductInfoAccordion
                      product={product}
                      lipscoreNavigationRef={lipscoreNavigationRef}
                    />
                  </>
                )
              }
            </Below>
          </div>
        </ProductSection>
      </Container>
      {customHierarchy.length ? (
        <HelloRetailFetcher
          customHierarchies={[customHierarchy]}
          customPathname={customPathname ? customPathname : null}
          boxKeyLabels={helloRetailProductPageBoxKeys}
        >
          {({ boxes }) => (
            <>
              <HelloRetailBoxRender
                className={'hello-retail-section-bottom'}
                helloRetailBoxes={helloRetailSectionPurchasedTogether}
                boxes={boxes}
              />
              <HelloRetailBoxRender
                className={'hello-retail-section-bottom'}
                helloRetailBoxes={helloRetailSectionAlternative}
                boxes={boxes}
              />
            </>
          )}
        </HelloRetailFetcher>
      ) : null}
      {relatedProducts && <RelatedProducts {...{ relatedProducts, loading }} />}
    </ProductContainer>
  );
};

export default Product;
