import React from 'react';
import { css } from 'linaria';
import { useIntl } from '@jetshop/intl';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theme';

const packageList = css`
  font-size: 0.875rem;
  margin-top: ${theme.space[2]};
  padding: 0;

  h3 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: ${theme.space[1]};
  }

  a {
    text-decoration: none;
    color: ${theme.colors.black};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export function IncludedInPackages({ packages }) {
  const t = useIntl();

  if (packages?.length === 0) return null;

  return (
    <div className={packageList}>
      <h3>{t('Included in package:')}</h3>
      <ul>
        {packages.map(pkg => {
          return (
            <li key={pkg.id}>
              <PackageLink pkg={pkg} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function PackageLink({ pkg }) {
  return <ProductLink product={pkg}>{pkg.name}</ProductLink>;
}
