import { css } from 'linaria';
import { theme } from '../Theme';

export const maxWidthBase = `
  width: 100%;
  max-width: ${theme.commonStyles.maxWidth};
  margin: 0 auto;
`;

export const maxWidth = css`
  ${maxWidthBase}
`;

export const edgePaddingBase = `
  padding-right: ${theme.elementSizes.edgePaddingSizeMu};
  padding-left: ${theme.elementSizes.edgePaddingSizeMu};

  ${theme.below.md} {
    padding-right: ${theme.elementSizes.edgePaddingSizeMd};
    padding-left: ${theme.elementSizes.edgePaddingSizeMd};
  }
`;

export const edgePadding = css`
  ${edgePaddingBase}
`;

export const containerBase = `
  ${edgePaddingBase}
  ${maxWidthBase}
`;

export const containerClass = css`
  ${containerBase}

  &.cc-xxs {
    max-width: ${theme.breakpoints.sm};
  }

  &.cc-xs {
    max-width: ${theme.breakpoints.md};
  }

  &.cc-s {
    max-width: ${theme.breakpoints.lg};
  }

  &.cc-m {
    max-width: ${theme.breakpoints.xl};
  }

  &.cc-fullwidth {
    max-width: none;
    padding: 0;
  }

  &.cc-content {
    max-width: ${theme.breakpoints.md};
  }
`;

export const containerEscape = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

export const marginClass = css`
  &.mc-xs {
    margin-bottom: ${theme.space[1]};
  }

  &.mc-s {
    margin-bottom: ${theme.space[2]};
  }

  &.mc-m {
    margin-bottom: ${theme.space[4]};
  }

  &.mc-l {
    margin-bottom: ${theme.space[6]};
  }

  ${theme.below.md} {
    &.mc-m {
      margin-bottom: ${theme.space[3]};
    }

    &.mc-l {
      margin-bottom: ${theme.space[4]};
    }
  }
`;

export const gapClass = css`
  display: flex;

  &.gc-s {
    column-gap: ${theme.space[2]};
  }

  &.gc-m {
    column-gap: ${theme.space[4]};
  }

  &.gc-l {
    column-gap: ${theme.space[6]};
  }

  & > * {
    flex: 1 1 100%;
  }

  ${theme.below.md} {
    &:not(.gc-md-row) {
      flex-direction: column;
      row-gap: ${theme.space[2]};
    }
  }
`;

export const layoutBase = css``;

export const sectionTitleCSS = css`
  margin-bottom: ${theme.space[2]};

  h1,
  h2,
  h3 {
    font-size: ${theme.themeFontSizes.xl};
  }

  ${theme.above.md} {
    margin-bottom: ${theme.space[3]};

    h1,
    h2,
    h3 {
      font-size: ${theme.themeFontSizes['4xl']};
    }
  }
`;

export const pseudoChevronCSS = css`
  &:after {
    content: '';
    display: inline-block;
    width: 16px;
    height: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.592' height='9.003' viewBox='0 0 16.592 9.003'%3E%3Cpath id='Path_5150' data-name='Path 5150' d='M1942.953,5619.5l7.943,7.943,7.943-7.943' transform='translate(-1942.6 -5619.146)' fill='none' stroke='%23000' stroke-width='1'/%3E%3C/svg%3E%0A");
  }
`;

export const pseudoCaretCSS = css`
  &:after {
    content: '';
    display: inline-block;
    width: 7px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.554' height='11.607' viewBox='0 0 6.554 11.607'%3E%3Cpath id='Path_5159' data-name='Path 5159' d='M0,0,4.743,4.743,9.486,0' transform='translate(1.061 10.546) rotate(-90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  }
`;

export const arrowButtonMDCSS = css`
  ${theme.below.md} {
    && {
      width: 40px;
      height: 40px;
      padding: 0;
      font-size: 0;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.323' height='11.295' viewBox='0 0 18.323 11.295'%3E%3Cline id='Line_19' data-name='Line 19' x2='16.512' transform='translate(0.75 5.647)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline id='Line_20' data-name='Line 20' x2='4.587' y2='4.587' transform='translate(12.675 1.061)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline id='Line_21' data-name='Line 21' y1='4.587' x2='4.587' transform='translate(12.675 5.647)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }
  }
`;

export const screenreaderCSS = css`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
`;

export const unmountingCSS = css`
  opacity: 0;
  animation-name: enter;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  &.unmounting {
    animation-name: exit;
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
