import PropTypes from 'prop-types';
import React from 'react';
import StockOrb from './StockOrb';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const StockStatusWrapper = styled('p')`
  /* width: 40%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${theme.space[2]};
  font-size: 12px;
  font-weight: ${theme.fontWeights.semibold};
`;

const StockStatusIndicator = ({ status, text, ...props }) => {
  return (
    <StockStatusWrapper className={'stock-status-wrapper'} {...props}>
      <StockOrb status={status} />
      <span>{text}</span>
    </StockStatusWrapper>
  );
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf([
    'inStock',
    'outOfStock',
    'notifyWhenBack',
    'loading'
  ]),
  text: PropTypes.string
};

export default StockStatusIndicator;
