import React from 'react';
import { styled } from 'linaria/react';

import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t, { useIntl } from '@jetshop/intl';
import { useMutation } from '@apollo/react-hooks';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { Currency } from '@jetshop/ui/Price/Currency';
import { theme } from '../Theme';
import CartItem from './CartItem';
import FreeShipping from './FreeShipping';
import { buttonSecondary } from '../../components/ui/Button';

import removeMultipleFromCart from './queries/removeMultipleFromCart.gql';
import cartQuery from './queries/cartQuery.gql';

const Flyout = styled('div')`
  background: ${theme.colors.bodyBackground};
  color: ${theme.colors.black};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  h2 {
    font-weight: ${theme.fontWeights.semibold};
    font-size: 1.25rem;
  }

  .free-shipping-div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: ${theme.space[1]};

    [data-flight-price] {
      font-weight: ${theme.fontWeights.semibold} !important;
    }
  }

  .discounts {
    padding: ${theme.elementSizes.flyoutEdgePadding};
  }

  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    padding-top: ${theme.space[1]};
    min-width: 19rem;
    max-width: 100%;
  }
`;

const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  padding: ${theme.elementSizes.flyoutEdgePadding};

  button {
    background: transparent;
    color: ${theme.colors.black};
    text-decoration: underline;
  }
`;

// const ItemCount = ({ count }) => (
//   <LightText>
//     {t(
//       `{
//         count, plural,
//         =0 {}
//         one {1 item}
//         other {{count} items}
//             }`,
//       { count }
//     )}
//   </LightText>
// );

const CartItems = styled('section')`
  background: ${theme.colors.bodyBackground};
  padding: 1rem;
`;

const Summary = styled('section')`
  display: flex;
  flex-direction: column;

  &:last-child {
    position: sticky;
    bottom: 0;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    padding: ${theme.elementSizes.flyoutEdgePadding};
    background: ${theme.colors.white};

    > * + * {
      margin-top: 0.5em;
    }

    .total,
    .vat {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${theme.colors.black};
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled.a`
  width: 100%;
  height: ${theme.elementSizes.buttonHeight};
  font-weight: ${theme.fontWeights.semibold};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CartFlyoutView = ({
  result,
  // modal,
  ...rest
}) => {
  const items = result?.data?.cart?.items ?? [];
  // const itemCount = result?.data?.cart?.totalQuantity ?? 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { pricesIncVat } = useChannelSettings();

  const cartId = result?.data?.cart?.id ?? null;
  const intl = useIntl();

  const [doEmptyCart] = useMutation(removeMultipleFromCart, {
    refetchQueries: [
      {
        query: cartQuery,
        variables: { cartId }
      }
    ]
  });

  const emptyCart = cart => {
    if (!window.confirm(intl('Are you sure?'))) {
      return false;
    }

    doEmptyCart({
      variables: {
        input: {
          cartId: cart.id,
          itemIds: cart.items.map(item => item.id)
        }
      }
    });
  };

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Summary>
        <Header>
          <h2> {t.rich('Your cart')}</h2>
          <button onClick={() => emptyCart(result?.data?.cart)}>
            {t('Empty cart')}
          </button>
          {/* <ItemCount count={itemCount} /> */}
        </Header>

        <CartItems>
          {items.map(item => (
            <CartItem item={item} key={item.id} cartId={cartId} />
          ))}
        </CartItems>

        {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name} style={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  <Price
                    price={discount.value}
                    style={{ display: 'inline' }}
                    negative
                  />
                </Flex>
              );
            })}
          </div>
        )}
      </Summary>
      <div className={'free-shipping-div'}>
        <FreeShipping
          className="shipping"
          cartTotal={result.data.cart.productTotal}
        />
      </div>
      <Summary>
        <div className="cart-total">
          <div className="total">
            <label>
              {/* {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')} */}
              {pricesIncVat ? t('Total') : t('Total')}
            </label>
            <Price price={result.data.cart.productTotal} />
          </div>

          <VAT
            className="vat"
            total={result.data.cart.productTotal}
            pricesIncVat={pricesIncVat}
          />
        </div>

        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            className={buttonSecondary}
            onClick={event => {
              event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: result.data.cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
    </Flyout>
  );
};

function VAT({ total, pricesIncVat, ...rest }) {
  return (
    <div {...rest}>
      {/* <label>{pricesIncVat ? t('of which VAT') : t('+ VAT')}</label> */}
      <label>{pricesIncVat ? t('Vat') : t('Vat')}</label>
      <div>
        <Currency value={total.vat} />
      </div>
    </div>
  );
}

const CartFlyout = props => (
  // Wrap the flyout with the needed providers

  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} size={435} right>
              <CartFlyoutView modal={drawer} result={result} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
