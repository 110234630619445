import React from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { useAddwish } from '@jetshop/flight-addwish';
import addwishProductsQuery from './HelloRetailProductsQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';

export const helloRetailBoxes = {
  TOP_PRODUCTS_IN_CATEGORY: 'TOP_PRODUCTS_IN_CATEGORY',
  NEW_ARRIVALS: 'NEW_ARRIVALS',
  POPULAR_ON_SALE: 'POPULAR_ON_SALE',
  RETARGETED: 'RETARGETED',
  TOP_MOST_PURCHASED_PRODUCTS: 'TOP_MOST_PURCHASED_PRODUCTS',
  ALTERNATIVES: 'ALTERNATIVES',
  PRODUCTS_PURCHASED_TOGETHER: 'PRODUCTS_PURCHASED_TOGETHER',
  PRODUCTS_PURCHASED_TOGETHER_UPSELL_STEP:
    'PRODUCTS_PURCHASED_TOGETHER_UPSELL_STEP',
  CART_PAGE: 'CART_PAGE',
  NOTFOUND_PAGE: 'NOTFOUND_PAGE'
};

export const helloRetailContentEditorLabels = {
  'New arrivals': helloRetailBoxes['NEW_ARRIVALS'],
  Retargeted: helloRetailBoxes['RETARGETED'],
  'Top products': helloRetailBoxes['TOP_MOST_PURCHASED_PRODUCTS']
};

const helloRetailBoxMap = {
  sv: {
    [helloRetailBoxes['TOP_PRODUCTS_IN_CATEGORY']]: 'k65fd98f3330a400bb6a31a2f',
    [helloRetailBoxes['NEW_ARRIVALS']]: 'k65fd987b68f841489c4dc489',
    // [helloRetailBoxes['POPULAR_ON_SALE']]: '',
    [helloRetailBoxes['RETARGETED']]: 'k65fd9847077e547e68de6c62',
    [helloRetailBoxes['TOP_MOST_PURCHASED_PRODUCTS']]:
      'k65fd984b68f841489c4dbad5',
    [helloRetailBoxes['ALTERNATIVES']]: 'k65fd98a9ed72502ef21f41fd',
    [helloRetailBoxes['PRODUCTS_PURCHASED_TOGETHER']]:
      'k65fd98bde497bd139ee08b19',
    //   [helloRetailBoxes['PRODUCTS_PURCHASED_TOGETHER_UPSELL_STEP']]:
    //     ''
    [helloRetailBoxes['CART_PAGE']]: 'k65fd98e3330a400bb6a316b8',
    [helloRetailBoxes['NOTFOUND_PAGE']]: 'k65fd98c4ed72502ef21f472d'
  }
};

export const getBoxLabelByNiceName = niceName => {
  return helloRetailContentEditorLabels?.[niceName];
};

export const getBoxLabelByKey = boxKey => {
  let boxLabel = null;

  Object.values(helloRetailBoxMap).some(channelBoxes => {
    return Object.entries(channelBoxes).some(([label, key]) => {
      if (key === boxKey) {
        boxLabel = label;
        return true;
      }

      return false;
    });
  });

  return boxLabel;
};

const useGetBoxData = (customPathname, customHierarchies, urls) => {
  const { selectedChannel } = React.useContext(ChannelContext);
  const { pathname } = useLocation();
  let hierarchies = null;

  const url = `${selectedChannel?.url}${
    customPathname ? customPathname : pathname
  }`;

  if (!customHierarchies) {
    hierarchies = [pathname?.substring(1)?.split('/')];
  }

  return { url, urls, hierarchies, selectedChannel };
};

export const useGetHelloRetailBoxesByKeys = (
  boxes = [],
  customPathname = null,
  customHierarchies = null,
  customUrls = null
) => {
  const { url, urls, hierarchies } = useGetBoxData(
    customPathname,
    customHierarchies,
    customUrls
  );

  return boxes?.reduce(
    (acc, boxKey) => ({
      ...acc,
      [boxKey]: {
        // url,
        ...(urls ? { urls } : { url }),
        hierarchies: customHierarchies ? customHierarchies : hierarchies
      }
    }),
    {}
  );
};

export const useGetHelloRetailBoxesByLabels = (
  boxes = [],
  customPathname = null,
  customHierarchies = null,
  customUrls = null
) => {
  const { url, urls, hierarchies } = useGetBoxData(
    customPathname,
    customHierarchies,
    customUrls
  );

  const channelKey = 'sv';

  return boxes?.reduce(
    (acc, boxKeyLabel) => ({
      ...acc,
      [helloRetailBoxMap?.[channelKey]?.[boxKeyLabel]]: {
        // url,
        ...(urls ? { urls } : { url }),
        hierarchies: customHierarchies ? customHierarchies : hierarchies,
        boxKeyLabel
      }
    }),
    {}
  );
};

export const usePopulateProductBoxes = ({ addWishResponseBoxItems }) => {
  const articleNumbers = Object.values(addWishResponseBoxItems).reduce(
    (acc, box) => {
      return [...acc, ...(box.items?.map(item => item.productNumber) || [])];
    },
    []
  );

  const { data, loading, error } = useQuery(addwishProductsQuery, {
    variables: { articleNumbers },
    skip: articleNumbers.length === 0,
    errorPolicy: 'ignore'
  });

  if (loading) {
    return { boxes: null, loading: true };
  }

  if (error) {
    return null;
  }

  const result = Object.entries(addWishResponseBoxItems).reduce(
    (acc, [boxKey, { items }]) => {
      const products = items.map(item => {
        const product = {
          ...data?.products.find(
            product => product.articleNumber === item.productNumber
          )
        };

        if (product) {
          product.addwishData = item;
        }

        return product;
      });

      return {
        ...acc,
        [getBoxLabelByKey(boxKey)]: products.filter(prod => prod?.id)
      };
    },
    {}
  );

  return { boxes: result, loading };
};

const HelloRetailFetcher = ({
  children,
  loading = false,
  boxKeyLabels = null,
  boxKeys = null,
  customPathname = null,
  customHierarchies = null,
  customUrls = null
}) => {
  const helloRetailBoxesByLabels =
    useGetHelloRetailBoxesByLabels(
      boxKeyLabels,
      customPathname,
      customHierarchies,
      customUrls
    ) ?? [];

  const helloRetailBoxesByKeys =
    useGetHelloRetailBoxesByKeys(
      boxKeys,
      customPathname,
      customHierarchies,
      customUrls
    ) ?? [];

  const helloRetailBoxes = {
    ...helloRetailBoxesByLabels,
    ...helloRetailBoxesByKeys
  };

  const { addWishResponseBoxItems } = useAddwish({
    boxes: helloRetailBoxes,
    pageLoaded: !loading
  });

  const addWishLoading =
    typeof addWishResponseBoxItems === 'object' &&
    !!Object.values(addWishResponseBoxItems)?.find(
      box => box?.loading === 'LOADING'
    );

  const { boxes, loading: populateBoxLoading } = usePopulateProductBoxes({
    addWishResponseBoxItems
  });

  return children({ boxes, loading: addWishLoading || populateBoxLoading });
};

export default HelloRetailFetcher;
