import React from 'react';
import { cx } from 'linaria';

const SearchBar = ({ className, placeholder = '' }) => {
  return (
    <div className={cx(className)}>
      <div>
        <input
          className={`${className}__input search-field`}
          type="search"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default SearchBar;
