import React from 'react';
import rafThrottle from '../Utils/rafThrottle';
import { theme } from '../../Theme';

const useScrollChecker = props => {
  const [scrolled, setScrolled] = React.useState(false);
  const topOffset = props?.scrolledTrigger
    ? props.scrolledTrigger
    : theme.sizes.headerHeightInt;
  const condition = props && 'condition' in props ? props?.condition : true;

  const handleScroll = rafThrottle(() => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollTop >= topOffset && !scrolled) {
      setScrolled(true);
    } else if (scrollTop < topOffset && scrolled) {
      setScrolled(false);
    }
  });

  React.useEffect(
    function() {
      if (condition) {
        if (typeof window !== 'undefined') {
          window.addEventListener('scroll', handleScroll);
        }

        return function() {
          if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', handleScroll);
          }
        };
      }
    },
    [scrolled, condition, handleScroll]
  );

  return props?.children ? props.children(scrolled) : scrolled;
};

export default useScrollChecker;
