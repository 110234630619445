import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { theme } from '../Theme';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import usePageSettings from '../Theme/Hooks/usePageSettings';

const newsletterCSS = css`
  width: 100%;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: ${theme.space[1]};
  }

  fieldset {
    border: 0;
    margin: 0;
    margin-top: ${theme.space[2]};
    padding: 0;
    position: relative;
  }

  &__gdpr {
    font-size: ${theme.fontSizes[1]};
    margin-top: ${theme.space[2]};

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      a {
        text-decoration: underline;
      }
    }

    input[type='checkbox'] {
      display: none;
    }
  }

  &__email {
    appearance: none;
    background: ${theme.colors.white};
    border: none;
    border-radius: 8px;
    font-size: ${theme.fontSizes[1]};
    outline: none;
    padding: ${theme.space[2]};
    width: 100%;
    padding-right: ${theme.space[8]};
    margin-top: ${theme.space[2]};
  }

  &__submit-button {
    appearance: none;
    width: 121px;
    background: ${theme.colors.primary};
    border-radius: 8px;
    padding: 13px;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes[2]};
    font-weight: ${theme.fontWeights.semibold};
    margin-top: ${theme.space[3]};
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }

  &__submit-button-desktop {
    appearance: none;
    background: ${theme.colors.black};
    border: 2px solid white;
    border-radius: 8px;
    padding: 12.5px ${theme.space[3]};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes[2]};
    font-weight: ${theme.fontWeights.semibold};
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &:hover {
      background: ${theme.colors.grey900};
    }
  }

  &__checkbox {
    border: 1px solid ${theme.colors.white};
    border-radius: 4px;
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: ${theme.space[1]};
    position: relative;

    &--checked {
      &:after {
        background: ${theme.colors.white};
        content: '';
        display: block;
        border-radius: 2px;
        height: 10px;
        width: 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }

  &__success {
    background: ${theme.colors.green600};
    border: 1px solid ${theme.colors.green600};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes[1]};
    margin-top: ${theme.space[1]};
    padding: ${theme.space[2]};
  }

  &__error {
    background: ${theme.colors.red};
    border: 1px solid ${theme.colors.red};
    color: ${theme.colors.red};
    font-size: ${theme.fontSizes[1]};
    margin-top: ${theme.space[1]};
    padding: ${theme.space[1]};
  }

  ${theme.above.md} {
    &__form {
      max-width: 534px;
    }
  }

  ${theme.above.lg} {
    flex-direction: row;
    max-width: 70rem;
    justify-content: flex-end;
    align-items: center;

    &__content-wrapper {
      width: 38%;
    }

    &__content {
      max-width: 386px;
      margin-left: auto;
    }
    &__form-wrapper {
      width: 62%;

      p {
        font-weight: ${theme.fontWeights.semibold};
      }
    }

    &__form {
      max-width: 534px;
      margin-left: auto;
    }
  }
`;

const base = newsletterCSS;

function NewsletterField() {
  const {
    inputProps,
    submit,
    submitting,
    submitted,
    failed,

    errorStates: { alreadySubscribed, invalidEmail }
  } = useNewsletterSubscription({
    enabled: true
  });

  const t = useIntl();
  const [accepted, setAccepted] = useState(false);
  const disabled = inputProps.value.length === 0 || submitting || !accepted;
  const settings = usePageSettings();

  const formSubmit = e => {
    if (disabled) {
      e.preventDefault();
      return false;
    }

    submit(e);
  };

  return (
    <div className={base}>
      <div className={`${base}__content-wrapper`}>
        <div className={`${base}__content`}>
          <h3 className={`${base}__title`}>
            {settings?.newsletterTitle
              ? settings.newsletterTitle
              : t('Join the newsletter')}
          </h3>
          {settings?.newsletterDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: settings.newsletterDescription
              }}
            />
          )}
          {(invalidEmail || failed) && (
            <div className={`${base}__error`}>
              {alreadySubscribed &&
                t('You have already subscribed to the newsletter!')}
              {!alreadySubscribed && (invalidEmail || failed)
                ? t(
                    'Something went wrong. Please check your email and try again.'
                  )
                : null}
            </div>
          )}
        </div>
      </div>
      <div className={`${base}__form-wrapper`}>
        <div className={`${base}__form`}>
          {submitted ? (
            <p
              data-testid="newsletter-subscription-success"
              className={`${base}__success`}
            >
              {t('You are now subscribed')}
            </p>
          ) : (
            <form onSubmit={formSubmit}>
              <fieldset>
                <p> {t('E-mail')}</p>
                <input
                  className={`${base}__email`}
                  type="email"
                  placeholder={t('Enter your e-mail')}
                  data-testid="newsletter-subscription-input"
                  {...inputProps}
                />
                <Above breakpoint="md">
                  {matches =>
                    matches && (
                      <input
                        className={`${base}__submit-button-desktop`}
                        type="submit"
                        disabled={disabled}
                        data-testid="newsletter-subscription-submit"
                        value={submitting ? t('Submitting…') : t('Join')}
                      />
                    )
                  }
                </Above>
              </fieldset>
              <div className={`${base}__gdpr`}>
                <label htmlFor="newsletter-gdpr">
                  <div
                    className={cx(
                      `${base}__checkbox`,
                      accepted && `${base}__checkbox--checked`
                    )}
                  />
                  <input
                    type="checkbox"
                    name="gdpr"
                    id="newsletter-gdpr"
                    onChange={e => {
                      setAccepted(e.target.checked);
                    }}
                  />
                  <span>
                    {t.rich('I agree to the {terms}*', {
                      terms: (
                        <Link
                          to={'/om-oss/integritetspolicy'}
                          key={t('terms of use')}
                        >
                          {t('terms of use')}
                        </Link>
                      )
                    })}
                    .
                  </span>
                </label>
              </div>
              <Below breakpoint="md">
                {matches =>
                  matches && (
                    <button
                      className={`${base}__submit-button`}
                      type="submit"
                      disabled={disabled}
                      data-testid="newsletter-subscription-submit-1"
                    >
                      {submitting ? t('Submitting…') : t('Join')}
                    </button>
                  )
                }
              </Below>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsletterField;
