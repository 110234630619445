import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../../Theme';

const ProductRatingWrapper = styled('div')`
  width: 100%;

  .lipscore-review-list-footer {
    display: none !important;
  }

  a {
    color: ${theme.colors.primary};
  }

  .no-reviews {
    display: none;
  }

  [data-ls-product-review-count='0'] + .no-reviews {
    display: block;
  }
`;

export const LipscoreReviewList = ({ product, brand, lipscoreRef }) => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <ProductRatingWrapper>
      <div
        ref={lipscoreRef}
        id="lipscore-review-list"
        // data-ls-product-id={product?.id}
        data-ls-product-id={product?.articleNumber}
        data-ls-product-name={product?.name}
        data-ls-brand={brand || ''}
        data-ls-product-url={selectedChannel?.url + product.primaryRoute?.path}
        data-ls-sku={product?.articleNumber}
        data-ls-image-url={
          product?.images?.length > 0 ? product?.images?.[0]?.url : null
        }
        data-ls-price={
          product?.price?.incVat === product?.previousPrice?.incVat
            ? product?.previousPrice?.incVat
            : product?.price?.incVat
        }
        data-ls-price-currency={selectedChannel?.currency?.name}
        data-ls-gtin={product?.barcode}
        data-ls-category={product?.canonicalCategory?.primaryRoute?.path}
        data-ls-description={product?.description}
      />
      <p className={'no-reviews'}>{t('No reviews yet')}</p>
    </ProductRatingWrapper>
  );
};
