import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';

const productCardLipscoreCSS = css`
  min-height: 16px;
  margin-top: ${theme.space[1]};

  .lipscore-star-incomplete .lipscore-star-last-half {
    fill: ${theme.colors.grey350};
  }

  .lipscore-rating-star.lipscore-star-empty {
    fill: ${theme.colors.grey350};
  }
`;

const baseCSS = productCardLipscoreCSS;

const LipscoreStar = ({ className }) => {
  return (
    <svg
      viewBox="0 0 19 18.1"
      fill={'#feb846'}
      width="16px"
      height="16px"
      className={cx(
        'lipscore-rating-star',
        'lipscore-svg-rating-star',
        className
      )}
      style={{ fillRule: 'evenodd' }}
    >
      <g transform="matrix(0.896868,0,0,0.983811,-0.279534,0)">
        <path d="M11.573,1.193L11.573,15.61L10.916,15.3L4.362,18.388L5.558,11.752L0.312,7.024L7.605,6.01L10.916,0L11.573,1.193Z"></path>
      </g>
      <g
        className="lipscore-star-last-half"
        transform="matrix(1,0,0,1,-133.489,-464)"
      >
        <path d="M142.989,464.021L143,464L145.97,469.912L152.511,470.91L147.805,475.561L148.878,482.09L143,479.052L142.989,479.058L142.989,464.021Z"></path>
      </g>
    </svg>
  );
};

const LipscoreRating = ({ rating }) => {
  const stars = [];
  const numbersOfStars = 5;

  for (let y = 0; y < rating; y++) {
    //Check if rating has decimals
    if (rating % 1 !== 0) {
      //If rating is higher than loop value but lower than the next loop integer: push a half star
      if (y === Math.floor(rating) && y !== rating) {
        stars.push(
          <LipscoreStar
            className={'lipscore-star-incomplete'}
            key={y + rating}
          />
        );
      } else {
        stars.push(<LipscoreStar key={y + rating} />);
      }
    } else {
      stars.push(<LipscoreStar key={y + rating} />);
    }
  }
  //Fill the remaining stars with non-filled stars
  if (stars.length < 5) {
    for (let i = stars.length; i < numbersOfStars; i++) {
      stars.push(
        <LipscoreStar className={'lipscore-star-empty'} key={i + rating} />
      );
    }
  }

  return <div>{stars}</div>;
};

const LipscoreProductCardRating = ({ product }) => {
  const lipscore = product?.lipscore;

  return (
    <div className={baseCSS}>
      {lipscore?.rating && Number(lipscore.rating) > 0 && (
        <LipscoreRating rating={lipscore.rating} />
      )}
    </div>
  );
};

export default LipscoreProductCardRating;
