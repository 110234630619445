import { ReactComponent as CircledPercentage } from '../../../svg/CircledPercentage.svg';
import { ReactComponent as Clothing } from '../../../svg/Clothing.svg';
import { ReactComponent as Finn } from '../../../svg/Finn.svg';
import { ReactComponent as FishingRod } from '../../../svg/FishingRod.svg';
import { ReactComponent as Rifle } from '../../../svg/Rifle.svg';
import { ReactComponent as Tag } from '../../../svg/Tag.svg';
import { ReactComponent as Tent } from '../../../svg/Tent.svg';
import { theme } from '../../Theme';

const categoryIconMap = categoryId =>
  ({
    [theme.categoryIds.jakt]: Rifle,
    [theme.categoryIds.friluftsliv]: Tent,
    [theme.categoryIds.klaer]: Clothing,
    [theme.categoryIds.fiske]: FishingRod,
    [theme.categoryIds.finnNO]: Finn,
    [theme.categoryIds.brands]: Tag,
    [theme.categoryIds.outlet]: CircledPercentage,
    [theme.categoryIds.campaign]: Tag
  }[categoryId] || null);

export default categoryIconMap;
