import React from 'react';
import { css, cx } from 'linaria';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
// import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import { theme } from '../../Theme';
import SearchBar from '../../Layout/Header/SearchBar';

export const searchFieldContainerCSS = css`
  &__input {
    width: 100%;
    padding: ${theme.space[1]} ${theme.space[2]};
    border: 0;
    border-radius: ${theme.commonStyles.borderRadius};
    outline: 0;
    font-size: 1rem;
    transition: box-shadow ease-in-out 0.2s;
    background: transparent;
    color: currentColor;

    &:focus {
      color: currentColor;
    }
  }
`;

const SearchFieldDesktop = ({
  // initialValue,
  className,
  placeholder = '',
  autocompleteQuery
  // closeDrawer,
  // setResetSearch,
  // ...rest
}) => {
  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );

  return (
    <div className={cx(className, searchFieldContainerCSS)}>
      <SearchBar
        className={searchFieldContainerCSS}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchFieldDesktop;
