import Gallery from '@jetshop/ui/Gallery/Gallery';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { css, cx } from 'linaria';
import { useSwitchToVariantImage } from '@jetshop/core/hooks/Product';
import { theme } from '../../Theme';

const wrapper = css`
  .image-gallery-fullscreen-button {
    display: none;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    justify-content: flex-start;
    margin-top: ${theme.space[1]};
    align-items: center;

    [data-flight-image-container] {
      padding-bottom: 100% !important;

      img {
        object-fit: cover !important;
      }
    }
    .image-gallery-thumbnail {
      width: 76px;
      height: 76px;
    }
  }
  .image-gallery-thumbnail {
    &.active {
      border: 1px solid black;
      transform: none;
      width: 70px;
      height: 70px;
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    border: 1px solid ${theme.colors.black};
    height: 19px;
    width: 19px;
    background: transparent;
    margin: 0 ${theme.space[3]};

    &::before {
      font-size: 0.8rem;
    }
  }

  [data-flight-image-container] {
    padding-bottom: 66.08% !important;
  }

  ${theme.below.md} {
    display: flex;
    flex-direction: column;
    margin-right: -${theme.space[1]};
    margin-left: -${theme.space[1]};

    [data-flight-image-container] {
      padding-bottom: 82.668% !important;
    }

    .image-gallery-thumbnails-wrapper {
      position: absolute;
      margin-top: 4px;
      width: 100%;
      height: 1rem;
      margin-right: 0;
      z-index: 2;
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      margin: 0 ${theme.space[1]};
    }

    .image-gallery-thumbnails-container {
      position: relative;
      justify-content: center;

      .image-gallery-thumbnail {
        width: 10px;
        height: 10px;
        margin: 0 0.25rem;
      }

      > a {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: ${theme.colors.grey400};

        &.active {
          background: ${theme.colors.black};
          border: 0;
        }

        > div {
          display: none;
        }
      }
    }
  }
`;

export default function ImageContainer({ images, badges, variant, ...rest }) {
  const galleryRef = React.createRef();
  // Enable switching gallery to image of selected variant
  useSwitchToVariantImage({
    galleryImages: images,
    galleryRef,
    variant
  });

  const selectedVariantImageIndex = variant
    ? images.findIndex(image => image.url === variant.images?.[0]?.url)
    : null;

  return (
    <div {...rest} className={cx(rest.className, wrapper)}>
      <Gallery
        aspect={'1:1'}
        images={images}
        galleryRef={galleryRef}
        eagerlyLoad={
          selectedVariantImageIndex ? [0, selectedVariantImageIndex] : [0]
        }
      >
        <Badges badges={badges} />
      </Gallery>
    </div>
  );
}
