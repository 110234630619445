import { css } from 'linaria';

import SwitzerWoff from './Switzer/Switzer-Regular.woff';
import SwitzerWoff2 from './Switzer/Switzer-Regular.woff2';

import SwitzerWoffBold from './Switzer/Switzer-Bold.woff';
import SwitzerWoff2Bold from './Switzer/Switzer-Bold.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Switzer';
        src: local('Switzer Regular'), local('Switzer-Regular'),
          url(${SwitzerWoff2}) format('woff2'),
          url(${SwitzerWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Switzer';
        src: local('Switzer Bold'), local('Switzer-Bold'),
          url(${SwitzerWoff2Bold}) format('woff2'),
          url(${SwitzerWoffBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      &.switzer-ready body {
        font-family: 'Switzer', sans-serif;
        line-height: 1.5;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
