import React from 'react';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { Below } from '@jetshop/ui/Breakpoints';
import { ProductSection as UIProductSection } from './ProductInfo';
import { theme } from '../Theme';
import { pseudoChevronCSS } from '../Theme/ThemeClasses';
import { getCustomFieldValues } from '../Theme/Utils/getCustomFields';
import {
  ContactForm,
  LipscoreReviewTab,
  productContentTypography
} from './ProductTabs';

const productSectionCSS = css`
  && {
    width: 100%;
    margin-top: ${theme.space[3]};
    padding-bottom: 0;
  }

  img {
    max-width: 100%;
  }

  & + & {
    margin-top: ${theme.space[1]};
  }

  &__button {
    width: 100%;
    background: transparent;
    color: ${theme.colors.black};
  }

  &__button-text {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 16px;

    &:after {
      transform: rotate(0);
      transition: transform ease-in-out 0.2s;
    }

    &--open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__accordion-inner {
    padding-top: ${theme.space[1]};
  }
`;

const base = productSectionCSS;

const lipscoreAccordionClassName = 'lipscore-accordion';

const LipscoreNavigationHelper = ({
  children,
  lipscoreNavigationRef,
  lipscoreAccordionClassName,
  handleClick,
  accordionIndex
}) => {
  React.useEffect(() => {
    if (lipscoreNavigationRef && lipscoreNavigationRef?.current === null) {
      lipscoreNavigationRef.current = {
        openReviewAccordion: () => handleClick(accordionIndex),
        lipscoreAccordionClassName: lipscoreAccordionClassName
      };
    }

    return () => {
      if (lipscoreNavigationRef && lipscoreNavigationRef?.current !== null) {
        lipscoreNavigationRef.current = null;
      }
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lipscoreNavigationRef]);

  return children;
};

const ProductInfoAccordion = ({ product, lipscoreNavigationRef }) => {
  const t = useIntl();

  const [specifications, sizeGuide] = getCustomFieldValues(
    ['specifications', 'size-guide'],
    product?.customFields
  );

  return (
    <Below breakpoint="md">
      {matches => (
        <Accordion single initialOpenIndexes={!matches ? [0] : []}>
          {({ openIndexes, handleClick, AccordionContent }) => (
            <>
              {product.description && (
                <UIProductSection className={base}>
                  <h3 className={`${base}__title`}>
                    <button
                      className={`${base}__button`}
                      onClick={() => handleClick(0)}
                    >
                      <span
                        className={cx(
                          `${base}__button-text`,
                          pseudoChevronCSS,
                          openIndexes.includes(0)
                            ? `${base}__button-text--open`
                            : null
                        )}
                      >
                        {t('Product description')}
                      </span>
                    </button>
                  </h3>
                  <AccordionContent isOpen={openIndexes.includes(0)}>
                    <article
                      className={cx(
                        `${base}__accordion-inner`,
                        productContentTypography
                      )}
                      dangerouslySetInnerHTML={{
                        __html: product.description
                      }}
                    />
                  </AccordionContent>
                </UIProductSection>
              )}
              {specifications && (
                <UIProductSection className={base}>
                  <h3 className={`${base}__title`}>
                    <button
                      className={`${base}__button`}
                      onClick={() => handleClick(1)}
                    >
                      <span
                        className={cx(
                          `${base}__button-text`,
                          pseudoChevronCSS,
                          openIndexes.includes(1)
                            ? `${base}__button-text--open`
                            : null
                        )}
                      >
                        {t('Specifications')}
                      </span>
                    </button>
                  </h3>
                  <AccordionContent isOpen={openIndexes.includes(1)}>
                    <div
                      className={cx(
                        `${base}__accordion-inner`,
                        productContentTypography
                      )}
                    >
                      <div className={`${base}__accordion-content`}>
                        <article
                          dangerouslySetInnerHTML={{
                            __html: specifications
                          }}
                        />
                      </div>
                    </div>
                  </AccordionContent>
                </UIProductSection>
              )}
              {sizeGuide && (
                <UIProductSection className={base}>
                  <h3
                    onClick={() => handleClick(2)}
                    className={`${base}__title`}
                  >
                    <button
                      className={`${base}__button`}
                      onClick={() => handleClick(2)}
                    >
                      <span
                        className={cx(
                          `${base}__button-text`,
                          pseudoChevronCSS,
                          openIndexes.includes(2)
                            ? `${base}__button-text--open`
                            : null
                        )}
                      >
                        {t('Size Guide')}
                      </span>
                    </button>
                  </h3>
                  <AccordionContent isOpen={openIndexes.includes(2)}>
                    <div
                      className={cx(
                        `${base}__accordion-inner`,
                        productContentTypography
                      )}
                    >
                      <article
                        className={cx(
                          `${base}__accordion-inner`,
                          productContentTypography
                        )}
                        dangerouslySetInnerHTML={{
                          __html: sizeGuide
                        }}
                      />
                    </div>
                  </AccordionContent>
                </UIProductSection>
              )}

              <UIProductSection className={base}>
                <h3 onClick={() => handleClick(3)} className={`${base}__title`}>
                  <button
                    className={`${base}__button`}
                    onClick={() => handleClick(3)}
                  >
                    <span
                      className={cx(
                        `${base}__button-text`,
                        pseudoChevronCSS,
                        openIndexes.includes(3)
                          ? `${base}__button-text--open`
                          : null
                      )}
                    >
                      {t('Contact')}
                    </span>
                  </button>
                </h3>
                <AccordionContent isOpen={openIndexes.includes(3)}>
                  <div
                    className={cx(
                      `${base}__accordion-inner`,
                      productContentTypography
                    )}
                  >
                    <ContactForm />
                  </div>
                </AccordionContent>
              </UIProductSection>
              <LipscoreNavigationHelper
                lipscoreNavigationRef={lipscoreNavigationRef}
                lipscoreAccordionClassName={lipscoreAccordionClassName}
                handleClick={handleClick}
                accordionIndex={4}
              >
                <UIProductSection className={base}>
                  <h3
                    onClick={() => handleClick(4)}
                    className={cx(`${base}__title`, lipscoreAccordionClassName)}
                  >
                    <button
                      className={`${base}__button`}
                      onClick={() => handleClick(4)}
                    >
                      <span
                        className={cx(
                          `${base}__button-text`,
                          pseudoChevronCSS,
                          openIndexes.includes(4)
                            ? `${base}__button-text--open`
                            : null
                        )}
                      >
                        {t('Reviews')}
                      </span>
                    </button>
                  </h3>
                  <AccordionContent isOpen={openIndexes.includes(4)}>
                    <div
                      className={cx(
                        `${base}__accordion-inner`,
                        productContentTypography
                      )}
                    >
                      <LipscoreReviewTab product={product} />
                    </div>
                  </AccordionContent>
                </UIProductSection>
              </LipscoreNavigationHelper>
            </>
          )}
        </Accordion>
      )}
    </Below>
  );
};

export default ProductInfoAccordion;
