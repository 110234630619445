import React from 'react';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import StockOrb from './StockStatus/StockOrb';
import ButtonWithLoading, { buttonQuintary } from '../ui/Button';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { ReactComponent as Check } from '../../svg/Check.svg';
import { theme } from '../Theme';

export function VariantSelector({
  product,
  variantHandler,
  showValidation,
  disableOutOfStock
}) {
  return (
    <div className={sharedStyles}>
      {product.variants.options.map((option, index) => {
        let showValidationMessage = false;

        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        if (option.values.length < 1) {
          return (
            <ButtonSelect
              option={option}
              variantHandler={variantHandler}
              key={option.name}
              showValidationMessage={showValidationMessage}
              disableOutOfStock={disableOutOfStock}
              doNotDisable={index === 0}
            />
          );
        }

        return (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            variantHandler={variantHandler}
            showValidationMessage={showValidationMessage}
            doNotDisable={index === 0}
          />
        );
      })}
    </div>
  );
}

export const sharedStyles = css`
  label {
    display: flex;
    align-items: center;
    margin-bottom: ${theme.space[1]};
    font-size: 1rem;
    font-weight: 700;
    margin-top: ${theme.space[1]};
  }

  .invalid {
    label {
      color: #eb5757;
    }
  }

  .missingVariant {
    margin-right: 0.25em;
    height: 10px;
    width: 10px;
  }
`;

export const dropdownStyles = css`
  & + & {
    margin-top: ${theme.space[2]};
  }

  [data-flight-dropdown-button] {
    display: flex;
    align-items: center;
    width: 100%;
    height: ${theme.elementSizes.buttonHeight};
    border: 1px solid ${theme.colors.grey400};
    border-radius: ${theme.commonStyles.borderRadius};
    background: ${theme.colors.white};
    color: ${theme.colors.black};

    > div {
      span {
        &:last-child {
          text-transform: lowercase;
        }
      }
    }
  }

  [data-flight-dropdown-open='true'] {
    [data-flight-dropdown-button] {
      border-bottom-color: transparent;
      border-radius: 8px 8px 0 0;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  [data-flight-dropdown-items] {
    width: 100%;
    border: 1px solid ${theme.colors.grey400};
    margin-top: -1px;
    border-radius: 0 0 8px 8px;
    border-top: 0;
  }

  [data-flight-dropdown-item] {
    display: flex;
    align-items: center;
    border-top: 0;
    justify-content: space-between;

    .out-of-stock-text {
      color: ${theme.colors.red600};
      font-size: 12px;
    }

    label {
      width: 100%;
    }
    &:first-child {
      border-top: 1.5px solid ${theme.colors.fontDisableGrey};
    }

    &:last-child {
      &:hover {
        border-radius: 0 0 8px 8px;
      }
    }

    &.out-of-stock {
      color: ${theme.colors.fontDisableGrey};

      svg {
        margin: 0;
      }

      :hover {
        color: ${theme.colors.black};
      }
    }

    &.active {
      font-weight: ${theme.fontWeights.semibold};
      color: ${theme.colors.black};
    }

    &[data-disabled] {
      color: ${theme.colors.fontDisableGrey};
    }

    &:hover {
      color: ${theme.colors.black};
      background: ${theme.colors.inputHoverGrey};
    }
  }

  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-left: auto;
  }
`;

export const buttonSelectStyles = css`
  margin: 1rem 0;

  button {
    width: auto;

    + button {
      margin-left: ${theme.space[1]};
    }
  }

  &.invalid button {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

function ButtonSelect({
  option,
  variantHandler,
  showValidationMessage,
  disableOutOfStock,
  doNotDisable
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const selectedValue = getSelectedValue(option);
  const t = useIntl();

  if (option.values?.length < 1) return null;

  return (
    <div className={cx(buttonSelectStyles, showValidationMessage && 'invalid')}>
      <label>
        {showValidationMessage && <StockOrb className="missingVariant" />}
        {t('Select {optionName}', { optionName: option.name })}
      </label>
      {option.values.map(value => {
        const validation = validateSelection(value, option);

        return (
          <ButtonWithLoading
            data-testid={option.name + value}
            key={option.name + value}
            onClick={() => selectValue(value, option)}
            disabled={
              !doNotDisable &&
              (validation === 'invalid' ||
                (disableOutOfStock && validation === 'outOfStock'))
            }
            aria-pressed={value === selectedValue}
            className={value !== selectedValue ? buttonQuintary : ''}
          >
            {value}
          </ButtonWithLoading>
        );
      })}
    </div>
  );
}

function DropdownVariantSelect({
  option,
  variantHandler,
  showValidationMessage,
  doNotDisable
}) {
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const t = useIntl();

  const defaultValue = (
    <div>
      <span> {t.rich('Select {option}')}</span>
      <span>{option.name}</span>
    </div>
  );
  const selectedValue = getSelectedValue(option);

  return (
    <div className={cx(dropdownStyles, showValidationMessage && 'invalid')}>
      <label htmlFor={`option-${option.name}`}>
        {showValidationMessage && <StockOrb className="missingVariant" />}
        {option.name}
      </label>
      <DropdownMenu>
        <DropdownMenuButton id={`option-${option.name}`}>
          {selectedValue ? selectedValue : defaultValue}
          <Caret />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
          {option.values.map(value => {
            const validation = validateSelection(value, option);
            const outOfStock = validation === 'outOfStock';
            const active = selectedValue === value;

            return (
              <DropdownMenuItem
                data-testid={value + option.name}
                key={value + option.name}
                className={cx(active && 'active', outOfStock && 'out-of-stock')}
                disabled={!doNotDisable && validation === 'invalid'}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                }}
                data-disabled={validation === 'invalid' ? true : undefined}
              >
                <span> {value}</span>
                {outOfStock && (
                  <span className={'out-of-stock-text'}>
                    {t('Out of stock')}
                  </span>
                )}
                {!outOfStock && selectedValue === value && <Check />}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}
