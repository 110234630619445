/* eslint-disable */
import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { theme } from './components/Theme';

css`
  :global() {
    ${resets};
  }
`;

const fallbackStyles = `
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Switzer',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'Arial', 'sans-serif';
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  visibility: visible;
`;

const resets = `
    html,
    body {
      height: 100%;
      background: ${theme.colors.bodyBackground};
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Switzer',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Arial', 'sans-serif';
    }
    html,
    body,
    #root {
      min-height: 100%;

    }
    #root {
      display: flex;
      flex-direction: column;
    }
    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
    }
    a {
      color: currentColor;
    }
    p,
    d,
    ol,
    ul,
    h4,
    h3,
    h2,
    h1 {
      margin: 0;
      padding: 0;
    }
    ul {
      list-style: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 1.125;
      font-weight: 700;
    }
    h1 {
      font-size: 2.375rem;
    }
    h2 {
      font-size: 1.625rem;
    }
    h3 {
      font-size: 1.25rem;
    }
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 1rem;
    }
    ${theme.above.md} {
      h1 {
        font-size: 3.5rem;
      }
      h2 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 1.6rem;
      }
      h4 {
        font-size: 1.25rem;
      }
      h5 {
        font-size: 1rem;
      }
      h6 {
        font-size: 0.75rem;
      }
    }
    input {
      -webkit-appearance: none;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }
    .ReactModal__Overlay--after-open {
      overflow: scroll;
    }
    body {
      ${fallbackStyles};
      -webkit-font-smoothing: antialiased;
    }
    /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
      color: #000 !important;
    }
    #CybotCookiebotDialogPoweredbyCybot {
      display: none !important;
    }
    #CybotCookiebotDialogPoweredByText {
      display: none !important;     
    }
    .CookiebotWidget-main-logo {
      display: none !important;     
    }
`;
