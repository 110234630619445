import React from 'react';
import { css } from 'linaria';
import { Query } from 'react-apollo';
import Image from '@jetshop/ui/Image/Image';
import productVariantsQuery from './queryGetRelatedVariants.gql';
import { theme } from '../Theme';
import ProductLink from '@jetshop/ui/ProductLink';

const relatedVariantsCSS = css`
  ul {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: ${theme.space[1]};
  }

  li {
    width: calc(20% - 10px);
    margin-right: 12px;
    margin-top: 12px;

    &:nth-child(5n) {
      margin-right: 0;
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;

      border: 1px solid transparent;
      border-radius: ${theme.commonStyles.borderRadius};

      img {
        border-radius: ${theme.commonStyles.borderRadius};
        object-fit: contain !important;
        background: ${theme.colors.white};
      }

      &.active {
        border: 1px solid ${theme.colors.black};
      }
    }
  }
`;

const RelatedVariants = ({
  productVariants,
  productArticleNumber,
  headingText
}) => {
  if (!productArticleNumber) return null;

  const sortedProductVariants = [...productVariants].sort((a, b) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Query
        query={productVariantsQuery}
        variables={{ articleNumbers: sortedProductVariants }}
        errorPolicy={'all'}
      >
        {({ data, loading }) => {
          // if (loading || error) return null;
          if (loading) return null;
          const { products } = data;
          let hasActiveProduct = false;

          if (!products) {
            return null;
          } else {
            const variantList = products
              .map(queryProduct => {
                const {
                  id,
                  primaryRoute,
                  images,
                  articleNumber
                } = queryProduct;
                const product = { id, primaryRoute, images, articleNumber };
                const activeClass =
                  productArticleNumber === product.articleNumber
                    ? 'active'
                    : null;

                hasActiveProduct = !!activeClass;

                return (
                  <li key={id}>
                    <ProductLink
                      product={product}
                      className={activeClass}
                      data-product-id={id}
                    >
                      {product.images?.[0]?.url && (
                        <Image
                          cover={true}
                          src={product.images[0].url}
                          alt={product.images[0]?.alt}
                        />
                      )}
                    </ProductLink>
                  </li>
                );
              })
              .sort(
                (a, b) =>
                  a.props['data-product-id'] - b.props['data-product-id']
              );

            if (hasActiveProduct && variantList?.length === 1) return null;

            return variantList?.length > 0 ? (
              <>
                {headingText && (
                  <h3 className={'related-variants-name'}>{headingText}</h3>
                )}

                <div className={relatedVariantsCSS}>
                  <ul>{variantList}</ul>
                </div>
              </>
            ) : null;
          }
        }}
      </Query>
    </>
  );
};

export default RelatedVariants;
