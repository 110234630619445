// import ChannelContext from '@jetshop/core/components/ChannelContext';
import React from 'react';
import { theme } from '../../Theme';
import { useScript } from '../Hooks/useScript';

export const useLipscoreLoader = () => {
  // const { selectedChannel } = useContext(ChannelContext);
  // const language = selectedChannel?.language?.culture
  //   ?.split('-')?.[1]
  //   ?.toLowerCase();
  const language = 'no';

  const [lipscoreScriptLoaded, setLipscoreScriptLoaded] = React.useState(false);

  const [
    lipscoreWidgetsInitialized,
    setLipscoreWidgetsInitialized
  ] = React.useState(false);
  const lipscoreRef = React.useRef();
  const apiKey = theme?.lipscoreAPIKey;

  const useScriptStatus = useScript(
    `//static.lipscore.com/assets/${language}/lipscore-v1.js`,
    () => {
      setLipscoreScriptLoaded(true);
    }
  );

  React.useEffect(() => {
    if (useScriptStatus) {
      setLipscoreScriptLoaded(true);
    }
  }, [useScriptStatus]);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (lipscoreScriptLoaded && apiKey) {
        const windowLipscoreInitialized = window?.lipscore?.isInitialized();

        if (windowLipscoreInitialized && !lipscoreWidgetsInitialized) {
          window.lipscore.reInitWidgets();

          setLipscoreWidgetsInitialized(true);
        } else if (windowLipscoreInitialized) {
          window.lipscore.initWidgets();

          setLipscoreWidgetsInitialized(true);
        } else if (window?.lipscore) {
          window.lipscore.init({
            apiKey
          });

          setTimeout(() => {
            window.lipscore.initWidgets();

            setLipscoreWidgetsInitialized(true);
          }, 300);
        } else {
          console.warn('Lipscore missing from window object');
        }
      }
    }
  }, [
    apiKey,
    lipscoreScriptLoaded,
    useScriptStatus,
    lipscoreWidgetsInitialized
  ]);

  return { lipscoreWidgetsInitialized, lipscoreRef };
};
