import React from 'react';
import { css, cx } from 'linaria';

const verticalScrollerHeight = '1.25rem';

const verticalScrollerCSS = css`
  height: ${verticalScrollerHeight};
  position: relative;
  overflow: hidden;

  &__scroller {
    height: ${verticalScrollerHeight};
    will-change: transform;

    &--animate {
      transition: transform cubic-bezier(0, 0.2, 1, 0.8) 0.325s;
    }
  }

  &__scroll-item {
    height: ${verticalScrollerHeight};
    display: flex;
    align-items: center;
  }
`;

const base = verticalScrollerCSS;

const VerticalCarousel = ({ items, interval = 4000, className }) => {
  const carouselItems = React.useMemo(
    () => [...items, ...(items?.length > 1 ? [items[0]] : [])],
    [items]
  );

  const [activeItem, setActiveItem] = React.useState(0);
  const [animate, setAnimate] = React.useState(true);
  const [pausedCarousel, setPausedCarousel] = React.useState(false);
  const carouselIntervalMiliseconds = interval;

  const toggleInterval = () => {
    if (!pausedCarousel) {
      setAnimate(false);
    }

    setPausedCarousel(!pausedCarousel);
  };

  React.useEffect(() => {
    if (pausedCarousel) return;
    if (!animate) clearInterval();

    const carouselInterval = setInterval(
      () => {
        setActiveItem(s => {
          if (s === carouselItems.length - 1) {
            setAnimate(false);
            return 0;
          }

          setAnimate(true);

          return s + 1;
        });
      },
      animate ? carouselIntervalMiliseconds : 0
    );
    return () => clearInterval(carouselInterval);
  }, [carouselItems, animate, carouselIntervalMiliseconds, pausedCarousel]);

  return carouselItems?.length > 0 ? (
    <div
      className={cx('vertical-carousel', base, className)}
      onClick={toggleInterval}
    >
      <div
        style={{
          transform: `translateY(${-(100 * activeItem)}%)`
        }}
        className={cx(
          `${base}__scroller`,
          animate ? `${base}__scroller--animate` : null
        )}
      >
        {carouselItems?.map((item, i) => {
          return (
            <div key={i} className={`${base}__scroll-item`}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default VerticalCarousel;
