import React from 'react';
import { Link } from 'react-router-dom';
import usePageSettings from '../../Theme/Hooks/usePageSettings';
import { isExternalLink } from '../../Theme/shared';

const HighlightedLinks = ({ className, liClass, linkClass }) => {
  const settings = usePageSettings();

  return (
    <ul className={className}>
      {settings?.menuLinks &&
        settings?.menuLinks.map(item => (
          <li key={item.title} className={liClass}>
            {isExternalLink(item.link) ? (
              <a
                href={item.link}
                className={linkClass}
                target="_blank"
                rel="noreferrer"
              >
                {item.title}
              </a>
            ) : (
              <Link to={item.link} className={linkClass}>
                {item.title}
              </Link>
            )}
          </li>
        ))}
    </ul>
  );
};

export default HighlightedLinks;
