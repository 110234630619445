import React from 'react';
import { Link } from 'react-router-dom';
import usePageSettings from '../../Theme/Hooks/usePageSettings';

const PageLinks = ({
  className,
  liClass,
  linkClass,
  customLinkItems = null
}) => {
  const settings = usePageSettings();
  const pageLinks = settings?.headerLinks;

  return (
    <ul className={className}>
      {pageLinks &&
        pageLinks.map(item => (
          <li key={item.link} className={liClass}>
            <Link to={item.link} className={linkClass}>
              {item.title}
            </Link>
          </li>
        ))}
      {customLinkItems}
    </ul>
  );
};

export default PageLinks;
