import React from 'react';
import { css, cx } from 'linaria';
import t, { useIntl } from '@jetshop/intl';
import { theme } from '../Theme';
import { getCustomFieldValues } from '../Theme/Utils/getCustomFields';
import ReadMore from '../Theme/ReadMore';
import ProductSpecifications, {
  getSpecifcations
} from './ProductSpecifications';
import { LipscoreReviewList, useLipscoreLoader } from '../Theme/Lipscore';
import { useScript } from '../Theme/Hooks/useScript';
// import ProductInformationTable from './ProductInformationTable';
// import { getMoreInformation } from './shared';

export const productContentTypography = css`
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol,
  table {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
  }
`;

const productTabsCSS = css`
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-top: ${theme.space[6]};

  .tab-buttons {
    width: 100%;
    p {
      font-weight: ${theme.fontWeights.semibold};
    }

    button {
      margin-right: ${theme.space[3]};
      background: none;
      color: ${theme.colors.fontGrey};
    }

    .button-active {
      position: relative;
      color: ${theme.colors.black};

      &:after {
        position: absolute;
        content: '';
        border-bottom: 3px solid ${theme.colors.black};
        width: 100%;
        transform: translateX(-50%);
        bottom: -14px;
      }
    }
  }

  .tab-content {
    width: 100%;
    overflow-x: auto;
    padding: ${theme.space[6]} 0;
  }

  .read-more {
    > div {
      min-height: 7.7rem;
    }
  }

  .size-guide {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const base = productTabsCSS;

export const ContactForm = () => {
  const formClassSelector = 'hubspot-form-container';
  const scriptStatus = useScript('//js-eu1.hsforms.net/forms/embed/v2.js');
  const scriptIsReady = scriptStatus === 'ready';

  React.useEffect(() => {
    if (scriptIsReady) {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: '139603784',
        formId: '79d5d37e-19ca-40b8-9868-12f6fde9c689',
        target: `.${formClassSelector}`
      });
    }
  }, [scriptIsReady]);

  return scriptIsReady ? <div className={formClassSelector}></div> : null;
};

export const LipscoreReviewTabTitle = () => {
  const [reviewCount, setReviewCount] = React.useState(null);
  const { lipscoreWidgetsInitialized } = useLipscoreLoader();

  React.useEffect(() => {
    if (typeof window !== undefined && lipscoreWidgetsInitialized) {
      setTimeout(() => {
        const lipscoreElement = document.querySelector('#lipscore-rating');

        if (lipscoreElement) {
          const reviewCount = lipscoreElement.dataset['lsProductReviewCount'];

          if (reviewCount) {
            setReviewCount(reviewCount);
          }
        }
      }, 200);
    }
  }, [lipscoreWidgetsInitialized, setReviewCount]);

  return (
    <p>
      {t('Reviews')}
      {reviewCount && ` (${reviewCount})`}
    </p>
  );
};

export const LipscoreReviewTab = ({ product }) => {
  const { lipscoreRef } = useLipscoreLoader();

  return <LipscoreReviewList product={product} lipscoreRef={lipscoreRef} />;
};

const tabContentClassName = 'tab-content';

const ProductTabs = ({ product, lipscoreNavigationRef }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const t = useIntl();

  const specifications = getSpecifcations(product?.customFields);

  const [sizeGuide] = getCustomFieldValues(
    ['size-guide'],
    product?.customFields
  );

  let tabs = [
    {
      key: 'description',
      tabTitle: <p>{t('Product Description')}</p>,
      children: (
        <div
          key={'description'}
          className={cx('product-description', productContentTypography)}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
      )
    }
  ];

  if (specifications?.length > 0) {
    tabs.push({
      key: 'specifications',
      tabTitle: <p>{t('Specifications')}</p>,
      children: <ProductSpecifications specifications={specifications} />
    });
  }

  if (sizeGuide) {
    tabs.push({
      key: 'size-guide',
      tabTitle: <p>{t('Size Guide')}</p>,
      children: (
        <div
          className="size-guide"
          dangerouslySetInnerHTML={{ __html: sizeGuide }}
        />
      )
    });
  }

  tabs.push({
    key: 'contact',
    tabTitle: <p>{t('Contact')}</p>,
    children: <ContactForm />
  });

  tabs.push({
    key: 'reviews',
    tabTitle: <LipscoreReviewTabTitle />,
    children: <LipscoreReviewTab product={product} />
  });

  React.useEffect(() => {
    if (lipscoreNavigationRef && lipscoreNavigationRef?.current === null) {
      lipscoreNavigationRef.current = {
        openLipscoreTab: () =>
          setActiveTabIndex(tabs.findIndex(tab => tab.key === 'reviews')),
        tabContentClassName: tabContentClassName
      };
    }

    return () => {
      if (lipscoreNavigationRef && lipscoreNavigationRef?.current !== null) {
        lipscoreNavigationRef.current = null;
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lipscoreNavigationRef, tabs.length]);

  return (
    <>
      <div className={base}>
        <ul className={'tab-buttons'}>
          {tabs?.length > 0
            ? tabs.map((tab, i) => {
                return (
                  <button
                    key={tab.key}
                    className={activeTabIndex === i ? 'button-active' : ''}
                    onClick={() => setActiveTabIndex(i)}
                  >
                    {tab.tabTitle}
                  </button>
                );
              })
            : null}
        </ul>
        <div className={tabContentClassName}>
          {activeTabIndex >= 0 && (
            <div>
              {tabs[activeTabIndex]?.html && (
                <>
                  <ReadMore condition={tabs[activeTabIndex].html?.length > 600}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: tabs[activeTabIndex].html
                      }}
                    />
                  </ReadMore>
                </>
              )}

              {tabs[activeTabIndex]?.children && tabs[activeTabIndex].children}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductTabs;
