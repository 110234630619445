import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t from '@jetshop/intl';
import { cx } from 'linaria';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import React from 'react';
import { dropdownStyles } from '../ProductPage/VariantSelector';

export function SelectVariant({ product, listId }) {
  const { update } = useProductList(listId);
  const initialVariant = product.variant;

  const {
    selectedVariant,
    selectValue,
    getSelectedValue,
    validateSelection
  } = useProductVariants(product, {
    initialVariant
  });

  React.useEffect(() => {
    // When a variant is selected, call the update function from useProductList to update the list
    function setVariant(variantArticleNumber) {
      update(product.articleNumber, {
        variantArticleNumber,
        variantToReplace: product.variant?.articleNumber,
        quantity: 1
      });
    }

    const currentArticleNumber = product.variant?.articleNumber;

    if (!selectedVariant) return;

    if (currentArticleNumber !== selectedVariant.articleNumber) {
      setVariant(selectedVariant.articleNumber);
    }
  }, [product.articleNumber, product.variant, selectedVariant, update]);

  return (
    <>
      {product.variants.options.map(option => {
        const selectedValue = getSelectedValue(option);
        const defaultValue = (
          <div>
            <span> {t.rich('Select {option}')}</span>
            <span>{option.name}</span>
          </div>
        );
        return (
          <div className={dropdownStyles}>
            <label htmlFor={`option-${option.name}`}>{option.name}</label>
            <DropdownMenu key={option.name}>
              <DropdownMenuButton>
                {selectedValue ? selectedValue : defaultValue}
                <Caret />
              </DropdownMenuButton>
              <DropdownMenuItems style={{ zIndex: 9999 }}>
                {option.values.map(value => {
                  const validation = validateSelection(value, option);
                  const outOfStock = validation === 'outOfStock';
                  const active = selectedValue === value;

                  return (
                    <DropdownMenuItem
                      key={value}
                      className={cx(
                        active && 'active',
                        outOfStock && 'out-of-stock'
                      )}
                      disabled={validation === 'invalid'}
                      closeOnSelect={true}
                      onSelect={() => {
                        selectValue(value, option);
                      }}
                      style={{ opacity: validation !== 'invalid' ? 1 : 0.5 }}
                    >
                      <span> {value}</span>
                      {outOfStock && (
                        <span className={'out-of-stock-text'}>
                          {t('Out of stock')}
                        </span>
                      )}
                      {!outOfStock && selectedValue === value && <Check />}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuItems>
            </DropdownMenu>
          </div>
        );
      })}
    </>
  );
}
