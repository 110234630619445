import React from 'react';
import { css, cx } from 'linaria';
import Glider from 'react-glider';
import { Below } from '@jetshop/ui/Breakpoints';
import { ProductCard } from '../../CategoryPage/ProductCard';
import { theme } from '../../Theme';
import { pseudoCaretCSS } from '../ThemeClasses';
import NativeScroll from '../NativeScroll';

const gliderCSS = css`
  li {
    list-style: none;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    background: ${theme.colors.white};
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.15);

    &:after {
      width: 8px;
      height: 16px;
    }

    &--left {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .glider,
  .glider-contain {
    margin: 0 -4px;
    position: relative;
  }

  .glider,
  .glider-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .glider-dot,
  .glider-next,
  .glider-prev {
    border: 0;
    padding: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
  }

  .glider-contain {
    width: 100%;
  }

  .glider {
    overflow-x: scroll;
  }

  .glider-track {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
  }

  .glider.draggable {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: grab;
  }

  .glider.draggable .glider-slide img {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .glider.drag {
    cursor: grabbing;
  }

  .glider-slide {
    width: 100%;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .glider-slide img {
    max-width: 100%;
  }

  .glider::-webkit-scrollbar {
    height: 0;
    opacity: 0;
  }

  .glider-next,
  .glider-prev {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 30%;
    z-index: 2;
    background: 0 0;
    font-size: 40px;
    text-decoration: none;
    color: #666;
    line-height: 1;
    opacity: 1;
    transition: opacity ease-in-out 0.2s, color ease-in-out 0.2s;
  }

  .glider-next:focus,
  .glider-next:hover,
  .glider-prev:focus,
  .glider-prev:hover {
    color: #ccc;
  }

  .glider-next {
    right: 0;
    left: auto;
  }

  .glider-next.disabled,
  .glider-prev.disabled {
    opacity: 0;
    pointer-events: none;
  }

  .glider-hide {
    opacity: 0;
  }

  .glider-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 6px;
    border-radius: 8px;
    position: relative;
  }

  .glider-dot {
    display: inline-block;
    padding: 0 30px;
    height: 6px;
    background-color: ${theme.colors.grey400};
    transition: background-color ease-in-out 0.2s;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &.active {
      background-color: #292929;
    }
  }

  ${theme.above.md} {
    .glider-track {
      padding-left: ${theme.elementSizes.edgePaddingSizeMu};
    }

    .glider-slide {
      &:last-child {
        margin-right: calc(${theme.elementSizes.edgePaddingSizeMu} - 0.5rem);
      }
    }

    .glider-prev {
      left: 0;
    }

    .glider-next {
      right: 0;
    }
  }

  @media (min-width: ${theme.commonStyles.maxWidth}) {
    // CSS for contained slider
    max-width: calc(${theme.commonStyles.maxWidth} + .5rem);
    padding-right: ${theme.elementSizes.edgePaddingSizeMu};
    padding-left: ${theme.elementSizes.edgePaddingSizeMu};
    margin: 0 auto;

    .glider-contain {
      margin-left: 0;
    }

    .glider-track {
      padding-left: 0;
    }

    .glider-slide {
      &:last-child {
        margin-right: 0;
      }
    }

    // CSS for fullwidth slider
    /* .glider-slide {
      &:last-child {
        margin-right: calc(
          (
              (100vw - ${theme.commonStyles.maxWidth}) +
                calc(${theme.elementSizes.edgePaddingSizeMu} + 0.5rem)
            ) / 2
        );
      }
    }

    .glider-track {
      padding-left: calc(
        (
            (100vw - ${theme.commonStyles.maxWidth}) +
              calc(${theme.elementSizes.edgePaddingSizeMu} * 2)
          ) / 2
      );
    }

    .glider-prev {
      left: calc(
        (
            (100vw - ${theme.commonStyles.maxWidth}) +
              calc(${theme.elementSizes.edgePaddingSizeMu} * 2)
          ) / 2
      );
    }

    .glider-next {
      right: calc(
        (
            (100vw - ${theme.commonStyles.maxWidth}) +
              calc(${theme.elementSizes.edgePaddingSizeMu} * 2)
          ) / 2
      );
    } */
  }
`;

const base = gliderCSS;

const customNativeSliderCSS = css`
  && {
    padding-left: 0;

    > * {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;

const GliderWrapper = ({ children }) => {
  const [showGlider, setShowGlider] = React.useState(false);
  const onLoad = () => setShowGlider(true);

  return (
    <div style={{ display: showGlider ? 'block' : 'none' }}>
      {children(onLoad)}
    </div>
  );
};

const SliderOrNativeScroll = ({ children, below, slidesToShowXL = 5 }) =>
  below ? (
    <NativeScroll className={customNativeSliderCSS}>{children}</NativeScroll>
  ) : (
    <Below breakpoint="xl">
      {matches => (
        <div className={base}>
          <GliderWrapper>
            {onLoad => (
              <Glider
                onLoad={onLoad}
                draggable
                dragVelocity={1}
                hasArrows
                hasDots
                scrollLockDelay={25}
                slidesToShow={matches ? 4.5 : slidesToShowXL}
                slidesToScroll={matches ? 4.5 : slidesToShowXL}
                dots={children?.length > 4 ? null : 'disable'}
                iconLeft={
                  <span
                    className={cx(
                      pseudoCaretCSS,
                      `${base}__icon`,
                      `${base}__icon--left`
                    )}
                  />
                }
                iconRight={
                  <span
                    className={cx(
                      pseudoCaretCSS,
                      `${base}__icon`,
                      `${base}__icon--right`
                    )}
                  />
                }
              >
                {children}
              </Glider>
            )}
          </GliderWrapper>
        </div>
      )}
    </Below>
  );

const ProductSlider = ({ products, slidesToShowXL = 5 }) => {
  return (
    <Below breakpoint="md">
      {matches => (
        <SliderOrNativeScroll below={matches} slidesToShowXL={slidesToShowXL}>
          {products?.map((product, i) => {
            return (
              <div key={i}>
                <ProductCard product={product} />
              </div>
            );
          })}
        </SliderOrNativeScroll>
      )}
    </Below>
  );
};

export default ProductSlider;
