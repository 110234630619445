import React from 'react';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { ReactComponent as CartIcon } from '../../svg/Cart.svg';
import Badge from '../ui/Badge';
import { theme } from '../Theme';
import cartQuery from './queries/cartQuery.gql';

const cartButtonCSS = css`
  position: relative;
  margin-right: 0;
  transition: margin-right ease-in-out 0.2s;
  will-change: margin-right;

  &--in-cart {
    margin-right: 0.375rem;
  }

  > .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.125rem;
    height: 1.125rem;
    position: absolute;
    top: -0.325rem;
    right: -0.5rem;
    background: ${theme.colors.cart.badgeBackground};
    font-size: 10px;
    font-weight: 700;
    color: ${theme.colors.cart.badgeText};

    > div {
      position: relative;
      bottom: 0.5px;
    }
  }
`;

function CartButton({ target, itemsInCart, className }) {
  const t = useIntl();

  return (
    <button
      aria-label={t('Cart')}
      className={cx(
        cartButtonCSS,
        className,
        itemsInCart ? `${cartButtonCSS}--in-cart` : null
      )}
      data-testid="cart-button"
      onClick={target.isOpen ? target.hideTarget : target.showTarget}
    >
      <span className={`${className}-inner`}>
        <CartIcon />
        {itemsInCart > 0 && <Badge text={itemsInCart} />}
      </span>
    </button>
  );
}

function CartButtonFlyout({ className }) {
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;

        // Drawer on mobile, flyout on desktop
        return (
          <>
            <DrawerTrigger preventOverflow={true} id="cart-drawer">
              {drawer => (
                <CartButton
                  className={className}
                  target={drawer}
                  itemsInCart={itemsInCart}
                />
              )}
            </DrawerTrigger>
          </>
        );
      }}
    </CartProvider>
  );
}

export default CartButtonFlyout;
