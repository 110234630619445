import React from 'react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theme';
import { containerClass } from '../../Theme/ThemeClasses';
import { ReactComponent as Logo } from '../../Theme/JaktOgFriluftLogo.svg';
import usePageSettings from '../../Theme/Hooks/usePageSettings';
import { isExternalLink } from '../../Theme/shared';
// import useGetPageLinks from '../../Theme/Hooks/useGetPageLinks';
// import NewsletterField from '../../Newsletter/NewsletterField';

const footerCSS = css`
  padding: ${theme.space[2]} 0;
  background: ${theme.colors.footer.background};
  color: ${theme.colors.footer.text};

  a {
    text-decoration: none;
    color: currentColor;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    margin-top: ${theme.space[1]};

    &:first-child {
      margin-top: ${theme.space[2]};
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    margin-top: ${theme.space[7]};
  }

  &__link-columns {
    display: flex;
    flex-direction: column;
    margin-top: ${theme.space[3]};
    margin-bottom: ${theme.space[2]};
  }

  &__link-column + &__link-column {
    margin-top: ${theme.space[4]};
  }

  &__brand-text {
    margin-top: ${theme.space[2]};
    max-width: 90%;
  }

  &__social-links__wrapper {
    margin-top: ${theme.space[2]};

    div {
      margin-top: ${theme.space[1]};
    }
  }

  &__newsletter-section {
    display: flex;
    width: 100%;
    justify-content: center;
    background: ${theme.colors.black};
    padding: ${theme.space[6]} ${theme.space[2]};

    ${theme.above.md} {
      padding: ${theme.space[6]} ${theme.space[4]};
    }
  }

  ${theme.above.sm} {
    &__brand {
      flex: 0 0 20%;
    }
  }

  ${theme.above.lg} {
    padding-bottom: ${theme.space[7]};

    &__brand {
      flex: 0 0 30%;
      max-width: 28rem;
    }

    &__inner {
      flex-direction: row;
      justify-content: space-between;
    }

    &__link-columns {
      justify-content: flex-end;
      flex-direction: row;
      margin-top: 0;
      margin-bottom: 0;
      flex: 0 0 60%;

      h3 {
        line-height: 1.25rem;
      }
    }

    &__link-column + &__link-column {
      margin-right: ${theme.space[2]};
      margin-left: ${theme.space[8]};
      margin-top: 0;
    }
  }

  ${theme.above.xl} {
    &__newsletter-section {
      padding: ${theme.space[3]} 0 ${theme.space[4]};
    }
  }
`;

const base = footerCSS;

const FooterLinks = ({ linkGroups }) => {
  return (
    <div className={`${base}__link-columns`}>
      {linkGroups.map(linkGroup => (
        <div key={linkGroup.title} className={`${base}__link-column`}>
          <h3>{linkGroup.title}</h3>
          <ul>
            {linkGroup.links.map(item => (
              <li key={item.title}>
                {isExternalLink(item.link) ? (
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.title}
                  </a>
                ) : (
                  <Link to={item.link}>{item.title}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const Footer = () => {
  const settings = usePageSettings();
  const footerLinks = settings?.footerLinkGroups
    ? settings?.footerLinkGroups
    : [];

  return (
    <footer className={base}>
      <div className={`${base}__newsletter-section`}>
        <NewsletterField />
      </div>
      <div className={cx(`${base}__inner`, containerClass)}>
        <div className={`${base}__brand`}>
          <Logo />
          {settings?.aboutStore && (
            <div
              className={`${base}__brand-text`}
              dangerouslySetInnerHTML={{ __html: settings.aboutStore }}
            />
          )}
        </div>
        {footerLinks.length > 0 && <FooterLinks linkGroups={footerLinks} />}
      </div>
    </footer>
  );
};

export default Footer;
