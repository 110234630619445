import React from 'react';
import { useProductList } from '@jetshop/core/hooks/ProductList';

const WindowFavouriteToggle = () => {
  const { toggle } = useProductList(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.toggleFavourite = toggle;
    }
  }, [toggle]);

  return null;
};

export default WindowFavouriteToggle;
