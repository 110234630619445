import React from 'react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import LipscoreProductCardRating from '../Theme/Lipscore/LipscoreProductCardRating';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theme';
import { isGiftCard } from '../ProductPage/ProductPage';

export const badgeTemplatesCSS = css`
  .badge-top-left,
  .badge-top-right,
  .badge-bottom-left,
  .badge-bottom-right {
    .orange {
      color: ${theme.colors.white};
      background: ${theme.colors.secondary};
    }
  }
`;

const productCardCSS = css`
  position: relative;
  font-size: 1rem;
  color: ${theme.colors.black};

  a {
    color: currentColor;
    text-decoration: none;
  }

  &__link {
    display: block;
    margin: 0 ${theme.space[1]} ${theme.space[4]};
    text-decoration: none;
    color: currentColor;
  }

  &__image {
    background: ${theme.colors.white};
  }

  &__detail {
    justify-content: space-between;
    grid-gap: ${theme.space[1]};
    margin-top: ${theme.space[2]};
  }

  &__name {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25;
  }

  &__sub-name {
    margin-top: ${theme.space[1]};
    overflow: hidden;
    font-size: 0.625rem;
    font-weight: 300;
    color: ${theme.colors.fontGrey};
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header {
    flex: 0 1 auto;
  }

  &__brand-wrapper {
    margin-top: 6px;
  }

  &__brand {
    text-decoration: none;
    font-size: 0.75rem;
    color: ${theme.colors.fontGrey};
  }

  &__price-wrapper {
    display: inline-block;
    flex: 1 0 auto;
    display: flex;
    margin-top: ${theme.space[1]};
  }

  div[data-flight-price] {
    display: flex;
    flex-direction: column;

    .price,
    .old-price,
    .new-price {
      font-size: 0.75rem;
      line-height: 1;
    }

    .new-price {
      margin-right: 0;
      text-align: right;
      color: ${theme.colors.red600};
    }
  }

  .package-price-label {
    font-size: 12px;
    color: grey;
    margin-left: 5px;
  }

  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }

  .badge-top-left {
    top: 0;
    color: ${theme.colors.white};
    font-size: 11px;
    width: auto;
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.semibold};

    > div {
      margin-top: ${theme.space[1]};

      &:first-child {
        margin-top: 0;
      }
    }

    span {
      padding: 6px 10px;
    }

    .new {
      background: ${theme.colors.orange600};
    }

    .discount {
      background: ${theme.colors.red600};
    }
  }

  ${theme.above.md} {
    margin-bottom: 1.125rem;

    &__name {
      font-size: 1rem;
    }

    &__sub-name {
      font-size: 0.75rem;
    }

    div[data-flight-price] {
      flex-direction: row;

      .new-price {
        margin-right: ${theme.space[1]};
      }

      .price,
      .old-price,
      .new-price {
        font-size: 1rem;
      }
    }
  }
`;

const base = productCardCSS;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  ...linkProps
}) {
  const hasDiscount = product?.price?.incVat < product?.previousPrice?.incVat;

  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...(product.badges || [])];
  product.isPackage &&
    badges.push({
      location: 'TOP_LEFT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  if (hasDiscount) {
    badges.unshift({
      location: 'TOP_LEFT',
      name: 'Discount',
      text:
        (
          ((product.previousPrice.incVat - product.price.incVat) /
            product.previousPrice.incVat) *
          100
        ).toFixed(0) + '%',
      style: 'discount'
    });
  }

  const brandCategory = product.categories?.find(
    cat => cat?.parent?.id === theme.categoryIds.brands
  );
  const isGiftCardProduct = isGiftCard(product);

  return (
    <Tag
      className={cx('product-card', base, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <div className={`${base}__link`}>
        {/* <DiscountBadge product={product} /> */}
        <ProductLink product={product} {...linkProps}>
          {hasImages ? (
            <Image
              className={cx(`${base}__image`, badgeTemplatesCSS)}
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              modifiedDate={product.images[0].modifiedDate}
              badges={<Badges badges={badges} />}
              critical={loadImageEagerly}
            />
          ) : (
            <Image src={transparentDataImg} />
          )}
        </ProductLink>
        <section className={`${base}__detail`}>
          <header className={`${base}__header`}>
            <h3 className={`${base}__name`}>
              <ProductLink product={product} {...linkProps}>
                {product.name}
              </ProductLink>
            </h3>
            {product?.subName && (
              <h4 className={`${base}__sub-name`}>{product.subName}</h4>
            )}
          </header>
          {brandCategory && (
            <div className={`${base}__brand-wrapper`}>
              <CategoryLink
                className={`${base}__brand`}
                category={brandCategory}
              />
            </div>
          )}
          {!isGiftCardProduct && (
            <div className={`${base}__price-wrapper`}>
              <Price
                hidePrice={product.hidePrice}
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </div>
          )}
          <LipscoreProductCardRating product={product} />
        </section>
      </div>

      <Favourite
        product={product}
        style={{
          position: 'absolute',
          padding: '0.5em',
          fontSize: '1.5em',
          top: '-0.6rem',
          right: '0.4em'
        }}
      />
      {children}
    </Tag>
  );
}
