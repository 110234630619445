const getFieldValue = field => {
  if (!field?.type) return null;

  const { type } = field;

  switch (type) {
    case 'STRING':
      return field?.['stringValue'] || null;

    case 'LIST':
      return field?.['listValues'] || null;

    case 'HTML':
      return field?.['htmlValue'] || null;

    case 'BOOL':
      const fieldValue = field?.['boolValue'];
      return typeof fieldValue === 'boolean' ? fieldValue : null;

    default:
      return null;
  }
};

const getCustomFieldValue = (key, customFields) => {
  if (!key || !customFields) return null;

  const fieldIndex = customFields.findIndex(field => field.key === key);

  return fieldIndex !== -1 ? getFieldValue(customFields[fieldIndex]) : null;
};

export const getCustomFieldValues = (keys = [], customFields) => {
  if (!Array.isArray(keys) || !customFields?.length > 0) return [];

  return keys.map(key => getCustomFieldValue(key, customFields));
};

export const pickCustomFields = (keys = [], customFields) => {
  if (!Array.isArray(keys) || !customFields?.length > 0) return [];

  return customFields.filter(field => keys.indexOf(field.key) !== -1);
};

export default getCustomFieldValue;
