import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { useIntl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { ReactComponent as MenuIcon } from '../../../svg/Menu.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theme';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import { Notifications } from '../Notifications';
import TopBar from './TopBar';
import Menu from './Menu';
import HighlightedLinks from './HighlightedLinks';
import { containerClass } from '../../Theme/ThemeClasses';
import { ReactComponent as Logo } from '../../Theme/JaktOgFriluftLogo.svg';
import { ReactComponent as HeartIcon } from '../../../svg/Heart.svg';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
// import SearchFlyout from './SearchFlyout';
import useScrollChecker from '../../Theme/Hooks/useScrollChecker';
import Badge from '../../ui/Badge';
import SearchFieldDesktop from '../../Theme/Search/SearchFieldDesktop';
import AutocompleteQuery from './AutocompleteQuery.gql';

const headerCSS = css`
  width: 100%;
  color: ${theme.colors.header.item};
  transition: ${theme.transitions.color};

  .l-fill {
    transition: fill ease-in-out 0.2s;
  }

  .start & {
    color: ${theme.colors.white};

    .l-fill {
      fill: ${theme.colors.header.logoFillStartpage};
    }
  }

  > .right,
  > .left {
    transition: ${theme.transitions.flyout};
  }

  > .open {
    overflow: hidden;
    max-width: calc(100% - 48px);
  }

  &--scrolled {
    color: ${theme.colors.black} !important;

    .l-fill {
      fill: ${theme.colors.header.logoFillScrolled} !important;
    }
  }

  &__floating-header {
    background: transparent;
    transition: ${theme.transitions.background}, ${theme.transitions.boxShadow};
  }

  &--scrolled > &__floating-header {
    position: fixed;
    top: 0;
    background: ${theme.colors.header.backgroundScrolled};
    box-shadow: ${theme.commonStyles.boxShadow};
  }

  &__floating-header {
    width: 100%;
    position: absolute;
    top: ${theme.elementSizes.topBarHeight};
    z-index: 3;
  }

  &__inner {
    height: ${theme.elementSizes.headerHeightMobile};
    justify-content: center;
  }

  &__item-wrapper,
  &__inner {
    display: flex;
    align-items: center;
  }

  &__item-wrapper--left {
    flex: 1;
    justify-content: left;
    margin-right: auto;
  }

  &__item-wrapper--right {
    flex: 1;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__item {
    flex: 0 0 auto;
    background: transparent;
    color: currentColor;

    &-wrapper--right & + & {
      margin-left: ${theme.space[2]};
    }

    &-wrapper--left & + & {
      margin-left: ${theme.space[2]};
    }
  }

  &__item:not(&__logo-link) {
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__item-inner {
    display: inline-flex;
    align-items: center;
    height: 100%;
    position: relative;

    .badge {
      position: absolute;
      font-size: 11px;
      min-height: 19px;
      min-width: 19px;
      padding: 2px;
      top: 0px;
      right: -10px;
      transform: translateY(-30%);

      :before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  &__logo-link-wrapper {
    text-align: center;
  }

  &__logo-link {
    display: inline-block;
  }

  &__logo {
    max-width: 150px;
  }

  &__menu-button-inner {
    display: inline-flex;
    align-items: center;
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }
  }

  &__menu-button-label {
    margin-left: ${theme.space[2]};
    font-weight: 700;
    opacity: 1;

    &:hover {
      opacity: 0.75;
    }
  }

  &__highlighted-links {
    display: flex;
    li {
      font-weight: 700;
      margin-left: ${theme.space[2]};
    }

    a {
      color: currentColor;
      text-decoration: none;
      opacity: 1;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  @media (min-width: 390px) {
    &__logo {
      max-width: none;
    }

    &__item:not(&__logo-link) {
      height: 30px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  ${theme.above.sm} {
    &:not(.start &) + main {
      margin-top: ${theme.elementSizes.headerHeightDesktop};
    }

    &__inner {
      height: ${theme.elementSizes.headerHeightDesktop};
    }

    &__item {
      &-wrapper--right & + & {
        margin-left: ${theme.space[3]};
      }

      &-wrapper--left & + & {
        margin-left: ${theme.space[3]};
      }
    }
  }

  ${theme.above.md} {
    &__item {
      &-wrapper--right & + & {
        margin-left: ${theme.space[2]};
      }

      &-wrapper--left & + & {
        margin-left: ${theme.space[2]};
      }
    }
  }
`;

const base = headerCSS;

const HeaderSearchButtonMobile = ({ intl }) => {
  return (
    // <DrawerTrigger preventOverflow={true} id="search-drawer">
    //  {drawer => (
    <button
      className={cx(`${base}__item`, `${base}__search-button`)}
      // onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
      aria-label={intl('Toggle searchfield')}
      // ref={container}
    >
      <span
        className={`${base}__item-inner`}
        style={{
          position: 'relative',
          zIndex: -1
        }}
      >
        <SearchIcon />
      </span>
    </button>
    //  )}
    // </DrawerTrigger>
  );
};

const HeaderSearchButtonDesktop = ({ intl, closeDrawer, setResetSearch }) => {
  return (
    <>
      <span className={`${base}__item-inner`}>
        <SearchIcon />
      </span>
      <SearchFieldDesktop
        className={`${base}__item-inner__title_search`}
        autocompleteQuery={AutocompleteQuery}
        onCancel={() => null}
        placeholder={intl('Search in the store...')}
        closeDrawer={closeDrawer}
        setResetSearch={setResetSearch}
      />
    </>
  );
};

const HeaderMenuButton = ({ intl, above }) => {
  return (
    <DrawerTrigger preventOverflow={true} id="menu-drawer">
      {drawer => (
        <button
          className={cx(`${base}__item`, `${base}__menu-button`)}
          onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
          aria-label={intl('Toggle menu')}
        >
          <span
            className={cx(`${base}__menu-button-inner`, `${base}__item-inner`)}
          >
            <MenuIcon />
            {above && (
              <span className={`${base}__menu-button-label`}>
                {intl('Menu')}
              </span>
            )}
          </span>
        </button>
      )}
    </DrawerTrigger>
  );
};

const HeaderScrollWrapper = ({ children, className }) => {
  const scrolled = useScrollChecker({
    scrolledTrigger: theme.elementSizesInt.topBarHeight
  });

  return (
    <header className={cx(className, scrolled ? `${base}--scrolled` : null)}>
      {children}
    </header>
  );
};

const HeaderFavoritesButton = ({ intl, above }) => {
  const { count } = useProductList();

  return (
    <Link
      aria-label={intl('Favourites')}
      to="/favourites"
      className={cx(`${base}__item`, `${base}__item--favourite`)}
    >
      <span className={`${base}__item-inner`}>
        {/* <span className={`${base}__item-inner__icon-wrapper`}> */}
        <HeartIcon />
        {count > 0 && <Badge text={count} />}
      </span>
      {above && (
        <span className={`${base}__item-inner__title`}>
          {intl('Favourites')}
        </span>
      )}
      {/* </span> */}
    </Link>
  );
};

const HeaderMain = () => {
  const t = useIntl();

  return (
    <HeaderScrollWrapper className={base}>
      <TopBar />
      <div className={`${base}__floating-header`}>
        <div className={cx(containerClass, `${base}__inner`)}>
          <Above breakpoint="md">
            {matches => (
              <>
                <div
                  className={cx(
                    `${base}__item-wrapper`,
                    `${base}__item-wrapper--left`
                  )}
                >
                  <HeaderMenuButton intl={t} above={matches} />
                  {matches && (
                    <HighlightedLinks
                      className={`${base}__highlighted-links`}
                    />
                  )}
                  {!matches && <HeaderSearchButtonMobile intl={t} />}
                </div>
                <div className={`${base}__logo-link-wrapper`}>
                  <Link
                    className={cx(`${base}__item`, `${base}__logo-link`)}
                    to="/"
                    aria-label={theme.storeName}
                  >
                    <Logo className={`${base}__logo`} />
                  </Link>
                </div>
                <div
                  className={cx(
                    `${base}__item-wrapper`,
                    `${base}__item-wrapper--right`
                  )}
                >
                  {matches && <HeaderSearchButtonDesktop intl={t} />}
                  <HeaderFavoritesButton intl={t} />
                  <CartButton className={`${base}__item`} />
                </div>
              </>
            )}
          </Above>
        </div>
      </div>
      <Menu />
      <CartFlyout />
      {/* <SearchFlyout /> */}
      <Notifications />
    </HeaderScrollWrapper>
  );
};

export default function Header() {
  return (
    <>
      <HeaderMain />
      <ChannelBanner />
    </>
  );
}
