import React, { useContext } from 'react';
import { css, cx } from 'linaria';
import { Below } from '@jetshop/ui/Breakpoints';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../../Theme';
import { useLipscoreLoader } from './useLipscoreLoader';
import LipscoreRatingLoadingState from './LipscoreRatingLoadingState';

export const lipscoreMinHeightPxInt = 26;

const hideLinkStyle = css`
  cursor: pointer;

  a {
    &.lipscore-rating-review-link {
      display: none;
    }
  }

  .lipscore-compact-rating-votes {
    display: none;
  }
`;
export const lipscoreContainerCSS = css`
  min-height: ${lipscoreMinHeightPxInt}px;
  margin-top: ${theme.space[1]};
`;

export const LipscoreRating = ({
  product,
  brand,
  lipscoreRef,
  lipscoreNavigationRef
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  const lipscoreLoader = useLipscoreLoader();
  const lipscoreLoaded = lipscoreLoader?.lipscoreWidgetsInitialized === true;

  return (
    <div className={cx(lipscoreContainerCSS, 'lipscore-container')}>
      {!lipscoreLoaded && <LipscoreRatingLoadingState />}
      <Below breakpoint="lgl">
        {matches => (
          <div
            className={hideLinkStyle}
            onClick={() => {
              // TODO - Fix effects in ProductTabs and ProductInfoAccordion to handle resize
              if (lipscoreNavigationRef?.current) {
                if (!matches) {
                  lipscoreNavigationRef.current.openLipscoreTab();

                  const tabContentElement = document.querySelector(
                    `.${lipscoreNavigationRef.current.tabContentClassName}`
                  );

                  if (tabContentElement) {
                    window.scrollTo({
                      top: tabContentElement.offsetTop,
                      behavior: 'smooth'
                    });
                  }
                } else {
                  const accordionElement = document.querySelector(
                    `.${lipscoreNavigationRef.current.lipscoreAccordionClassName}`
                  );

                  lipscoreNavigationRef.current.openReviewAccordion();

                  if (accordionElement) {
                    window.scrollTo({
                      top: accordionElement.offsetTop,
                      behavior: 'smooth'
                    });
                  }
                }
              }
            }}
            ref={lipscoreRef}
            id="lipscore-rating"
            ls-product-id={product?.articleNumber}
            ls-product-name={product?.name}
            ls-brand={brand || ''}
            ls-product-url={selectedChannel?.url + product.primaryRoute?.path}
            ls-sku={product?.articleNumber}
            ls-description={product?.description}
            ls-image-url={
              product?.images?.length > 0 ? product?.images?.[0]?.url : null
            }
            ls-price={
              product?.price?.incVat === product?.previousPrice?.incVat
                ? product?.previousPrice?.incVat
                : product?.price?.incVat
            }
            ls-price-currency={selectedChannel?.currency?.name}
            ls-availability={
              product?.stockStatus?.buyable
                ? product.stockStatus.buyable.toString()
                : 'false'
            }
            ls-category={
              product?.categories?.length > 0
                ? product?.categories?.[0]?.name
                : null
            }
          />
        )}
      </Below>
    </div>
  );
};
