import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
// import { ReactComponent as Caret } from '../../../svg/Caret.svg';
import usePageSettings from '../../Theme/Hooks/usePageSettings';
import USP, { createUSPItem, USPListCSS } from '../../Theme/USP';
import VerticalCarousel from '../../Theme/VerticalCarousel';
import { containerClass } from '../../Theme/ThemeClasses';
import { theme } from '../../Theme';
import PageLinks from './PageLinks';
// import ContentPageLink from '@jetshop/ui/ContentPageLink';
// import LanguageSelector from "./ChannelSelector/LanguageSelector";
// import useGetPageLinks from '../../Theme/Hooks/useGetPageLinks';

const topBarCSS = css`
  background: ${theme.colors.topBar.background};
  font-size: 0.825rem;
  color: ${theme.colors.topBar.item};

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${theme.elementSizes.topBarHeight};
    padding-top: ${theme.space[1]};
    padding-bottom: ${theme.space[1]};
  }

  ul {
    display: flex;
  }

  ul&__list {
    display: flex;
    justify-content: flex-end;
  }

  li + li {
    margin-left: ${theme.space[2]};
  }

  a {
    color: ${theme.colors.topBar.item};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .vertical-carousel {
    text-align: center;
  }

  &__language-selector {
    button {
      background: ${theme.colors.topBar.background};
      color: ${theme.colors.topBar.item};
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: ${theme.space[1]};
        width: 12px;
      }
    }
  }

  ${theme.above.md} {
    font-size: 0.75rem;

    &__inner {
      justify-content: center;

      > *:not(.usp-wrapper) {
        flex: 1;
      }
    }
  }

  ${theme.above.lg} {
    font-size: 0.825rem;
  }

  @media (min-width: ${theme.breakpoints.md}) and (max-width: 54rem) {
    font-size: 0.625rem;
  }
`;

const base = topBarCSS;

// const LanguageSelector = () => {
//   return (
//     <div className={`${base}__language-selector`}>
//       <button>
//         Norsk
//         <Caret />
//       </button>
//     </div>
//   );
// };

const MypagesLink = () => {
  const { loggedIn } = useAuth();
  const { loginPath, myPagesPath } = useShopConfig();

  return (
    <li className={`${base}__list-item`}>
      <Link to={loggedIn ? myPagesPath : loginPath}>
        {loggedIn ? t('My pages') : t('Login')}
      </Link>
    </li>
  );
};

const TopBar = () => {
  const settings = usePageSettings();
  const pageLinks = settings?.headerLinks;
  const USPItems = settings?.headerUsp?.length
    ? settings?.headerUsp.map(item => {
        return { text: item, icon: 'check' };
      })
    : false;
  const USPItemsWithIcon = USPItems
    ? USPItems.map(usp => createUSPItem(usp))
    : false;

  return (
    <div className={base}>
      <div className={cx(`${base}__inner`, containerClass)}>
        <Above breakpoint={'sm'}>
          {matches =>
            !matches && USPItemsWithIcon?.length > 0 ? (
              <>
                <VerticalCarousel
                  className={USPListCSS}
                  items={USPItemsWithIcon}
                />
                <ul className={`${base}__list`}>
                  <MypagesLink />
                </ul>
              </>
            ) : (
              <>
                {/* <LanguageSelector /> */}
                <div />
                {USPItems && <USP items={USPItems} />}
                {pageLinks ? (
                  <PageLinks
                    links={pageLinks}
                    className={`${base}__list`}
                    liClass={`${base}__list-item`}
                    customLinkItems={<MypagesLink />}
                  />
                ) : (
                  <ul className={`${base}__list`}>
                    <MypagesLink />
                  </ul>
                )}
              </>
            )
          }
        </Above>
      </div>
    </div>
  );
};

export default TopBar;
