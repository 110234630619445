import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import usePageSettings from '../../Theme/Hooks/usePageSettings';
import { isExternalLink } from '../../Theme/shared';
import { theme } from '../../Theme';

const campaignLinksCSS = css`
  background: ${theme.colors.secondary};
  padding: ${theme.elementSizes.flyoutEdgePadding} 0;
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeights.semibold};
`;

const CampaignLinks = ({ className, liClass, linkClass }) => {
  const settings = usePageSettings();

  return (
    <ul className={cx(className, campaignLinksCSS)}>
      {settings?.campaignLinks &&
        settings?.campaignLinks.map(item =>
          item?.link ? (
            <li key={item.title} className={liClass}>
              {isExternalLink(item.link) ? (
                <a
                  href={item.link}
                  className={linkClass}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.title}
                </a>
              ) : (
                <Link to={item.link} className={linkClass}>
                  {item.title}
                </Link>
              )}
            </li>
          ) : null
        )}
    </ul>
  );
};

export default CampaignLinks;
