import React from 'react';
import { css, cx } from 'linaria';
import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { theme } from '../../Theme';

const readMoreCSS = css`
  > div {
    min-height: 8.325rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 3rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(242, 242, 242, 1) 0%,
        rgba(242, 242, 242, 0) 100%
      );
      opacity: 0;
      transition: ${theme.transitions.opacity};

      pointer-events: none;
    }

    &[aria-hidden='true'] {
      &:after {
        opacity: 1;
      }
    }

    > div {
      display: block !important;
    }
  }

  &__button {
    margin-top: ${theme.space[2]};
    background: transparent;
    color: ${theme.colors.black};
    text-decoration: underline;
    font-weight: 700;
    text-decoration: none;
  }

  ${theme.above.md} {
    > div {
      min-height: 7.5rem;
    }
  }
`;

const base = readMoreCSS;

const ReadMore = ({ children, condition, callback }) => {
  return condition ? (
    <div className={cx(base, 'read-more')}>
      <Accordion>
        {({ openIndexes, handleClick, AccordionContent }) => (
          <>
            <AccordionContent isOpen={openIndexes.includes(0)}>
              {children}
            </AccordionContent>
            <button
              className={`${base}__button`}
              onClick={() => {
                callback && callback();
                handleClick(0);
              }}
            >
              {openIndexes.includes(0) ? t('- Show less') : t('+ Read more')}
            </button>
          </>
        )}
      </Accordion>
    </div>
  ) : (
    <>{children}</>
  );
};

export default ReadMore;
