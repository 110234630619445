import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Form, Field } from 'formik';

import AddToCartFormik from '@jetshop/core/cart/AddToCartFormik';
// import ProductConfigurationContext from '@jetshop/core/components/ProductConfigurationProvider/ProductConfigurationContext';
// import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import t from '@jetshop/intl';
// import { Price } from '@jetshop/ui/Price';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { PreOrderDateSelector } from '@jetshop/ui/PreOrderDateSelector';
import { useNotification } from '@jetshop/core/components/Notifications';
import Quantity from './Quantity';
import InputWithLabel from '../../Forms/Input';
import Button from '../../ui/Button';
import { IncludedInPackages } from '../PackageProduct/IncludedInPackages';
import { Campaigns } from '../Campaigns';
import NotifyWhenBack from '../StockStatus/NotifyWhenBack';
import StockStatusIndicator from '../StockStatus/StockStatusIndicator';
import { useProductValidationMessage } from '../useProductValidationMessage';
import { theme } from '../../Theme';
import ProductToastWrapper from './ProductToast';
import { ConfigurationSelector } from '../ConfigurationSelector';
import WarehouseStock from '../StockStatus/WarehouseStock';
import addToCartMutation from '../../Cart/queries/addToCart.gql';
import CartQuery from '../../Cart/queries/cartQuery.gql';
import { VariantSelector, dropdownStyles } from '../VariantSelector';
import RelatedVariants from '../RelatedVariants';
import { getCustomFieldValues } from '../../Theme/Utils/getCustomFields';
import { VariantSelectorGiftCard } from '../VariantSelectorGiftCard';
import { isGiftCard } from '../ProductPage';
// import { PriceHistoryWrapper } from '../PriceHistory';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    margin-top: ${theme.space[1]};
  }
`;

const QuantityAddToCartWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: ${theme.space[1]};
`;

const preOrderDateSelectorStyles = css`
  && {
    [data-flight-dropdown-items] {
      width: 100%;
      height: 200px;
      overflow: scroll;
    }
  }
`;

function AddToCartForm({
  product,
  variant,
  getMissingOptions,
  variantHandler
}) {
  const stockStatus = useStockStatus(variant || product);
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';
  const { validation: variantValidation, missingOptions } = variantHandler;
  const { hasVariants, hidePrice } = product;
  const { validationMessage, enableValidation } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus
  });
  const [trigger, dismiss] = useNotification();

  const distributionPackageSize = product?.distributionPackageSize ?? 1;

  const buyable = product?.stockStatus?.buyable;

  const minimumQuantity = distributionPackageSize
    ? Number(distributionPackageSize)
    : 1;

  const [productVariantsTitle, productVariants] = getCustomFieldValues(
    ['VariantTypeLabel', 'VariantProducts'],
    product?.customFields
  );

  const isGiftCardProduct = isGiftCard(product);

  const onAddToCartInit = ({ mutationId, quantity }) => {
    trigger(
      <ProductToastWrapper
        selectedVariation={variant}
        product={product}
        quantity={quantity}
      />,
      {
        // autoCloseAfter: 99999,
        id: mutationId,
        type: 'add-to-cart'
      }
    );
  };
  const onAddToCartError = () => {
    return ({ mutationId, quantity, error }) => {
      dismiss(mutationId);

      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={quantity}
          error={error}
        />,
        {
          type: 'add-to-cart'
        }
      );
    };
  };
  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
      getMissingOptions={getMissingOptions}
      initialValues={{
        quantity: minimumQuantity
      }}
    >
      {() => {
        return (
          <>
            {product.hasVariants && !isGiftCardProduct ? (
              <VariantSelector
                product={product}
                variantHandler={variantHandler}
                showValidation={!!validationMessage}
              />
            ) : (
              <VariantSelectorGiftCard
                product={product}
                variantHandler={variantHandler}
                showValidation={!!validationMessage}
              />
            )}
            {productVariants && productVariants.length > 0 && (
              <div
                className={cx(
                  'related-variants',
                  productVariants.length > 4 ? 'break' : undefined
                )}
              >
                <RelatedVariants
                  productArticleNumber={product.articleNumber}
                  productVariants={productVariants}
                  headingText={
                    productVariantsTitle ? productVariantsTitle : t('Variants')
                  }
                />
              </div>
            )}
            {product.hasConfigurations && (
              <ConfigurationSelector product={product} />
            )}
            <PreOrderDateSelector
              className={cx(dropdownStyles, preOrderDateSelectorStyles)}
              product={product}
            />
            {/* <Price
              hidePrice={product.hidePrice}
              {...price}
              css={{
                marginBottom: '24px',
                opacity:
                  configurationContext && configurationContext.loading ? 0.3 : 1
              }}
            />
            {!hidePrice && (
              <PriceHistoryWrapper
                articleNumber={product.articleNumber}
                variant={variant}
              />
            )} */}
            <Campaigns
              campaigns={product.campaigns}
              css={{ marginTop: '-16px', marginBottom: '24px' }}
            />

            <IncludedInPackages packages={product.inPackages} />

            <StyledForm>
              {product.customerComments &&
                product.customerComments.map(comment => (
                  <InputWithLabel
                    wrapperClassName="customer-comment-input-wrapper"
                    className="customer-comment-input"
                    data-testid={`${comment.name}-input`}
                    id={`comments['${comment.name}']`}
                    name={`comments['${comment.name}']`}
                    label={comment.name}
                    key={comment.name}
                  />
                ))}
              <StockStatusIndicator
                status={
                  product?.stockStatus?.text ? stockStatus.status : 'loading'
                }
                text={stockStatus.text}
              />
              {/* {!outOfStockNotify && (
                // <QuantityAddToCartWrapper>
                //   <InputWithLabel
                //     disableValidation
                //     wrapperClassName="quantity-input-wrapper"
                //     min="1"
                //     type="number"
                //     name="quantity"
                //     label="Quantity"
                //   />
                <Button
                  data-testid="add-to-cart"
                  type="submit"
                  disabled={!!validationMessage}
                  onClick={enableValidation}
                  style={{
                    opacity: !!validationMessage ? 0.7 : 1,
                    cursor: !!validationMessage ? 'not-allowed' : 'pointer',
                    width: '100%'
                  }}
                  data-videoly-label="buy"
                >
                  {validationMessage ? validationMessage : t('Add to cart')}
                </Button>
                // </QuantityAddToCartWrapper>
              )} */}
              <QuantityAddToCartWrapper>
                {outOfStockNotify && !hidePrice ? (
                  <NotifyWhenBack
                    articleNumber={(variant || product).articleNumber}
                  />
                ) : (
                  <>
                    {minimumQuantity === 1 ? (
                      <InputWithLabel
                        disableValidation
                        wrapperClassName="quantity-input-wrapper"
                        min="1"
                        type="number"
                        name="quantity"
                        // label="Quantity"
                      />
                    ) : (
                      <Field id="quantity" name="quantity">
                        {({ field, form: { setFieldValue } }) => (
                          <Quantity
                            field={field}
                            setQuantity={setFieldValue}
                            minimumQuantity={minimumQuantity}
                            quantity={field.value}
                          />
                        )}
                      </Field>
                    )}
                    <Button
                      data-testid="add-to-cart"
                      type="submit"
                      disabled={!buyable || !!validationMessage}
                      onClick={enableValidation}
                      style={{
                        opacity: !buyable || !!validationMessage ? 0.7 : 1,
                        cursor:
                          !buyable || !!validationMessage
                            ? 'not-allowed'
                            : 'pointer',
                        width: '100%'
                      }}
                    >
                      {validationMessage ? validationMessage : t('Add to cart')}
                    </Button>
                  </>
                )}
              </QuantityAddToCartWrapper>
            </StyledForm>
            {(product?.warehouseStock?.length > 0 ||
              product?.variants?.values[0]?.warehouseStock?.length > 0) && (
              <WarehouseStock
                product={product}
                variantHandler={variantHandler}
              />
            )}
          </>
        );
      }}
    </AddToCartFormik>
  );
}

export default AddToCartForm;
