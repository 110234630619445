import React from 'react';
import { useScript } from '../Hooks/useScript';

export const Videoly = ({ product }) => {
  useScript(`//api.videoly.co/1/quchbox/0/830/quch.js`);

  return (
    <div style={{ display: 'none' }}>
      {product?.id && <div id="videoly-product-id">{product.id}</div>}
      {product?.articleNumber && (
        <div id="videoly-product-sku">{product.articleNumber}</div>
      )}
      {product?.barcode && (
        <div className="videoly-product-gtin">{product.barcode}</div>
      )}
    </div>
  );
};

export default Videoly;
