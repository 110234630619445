import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';
import { containerEscape } from '../ThemeClasses';

const nativeScrollCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow-x: scroll;
  padding-left: calc(${theme.elementSizes.edgePaddingSizeMd});
  -webkit-overflow-scrolling: touch;

  li {
    list-style: none;
  }

  > * {
    flex: 1 0 45%;

    &:last-of-type {
      margin-right: calc(${theme.elementSizes.edgePaddingSizeMd});
    }
  }
`;

const baseCSS = nativeScrollCSS;

const NativeScroll = ({ children, className }) => {
  return (
    <div className={cx(baseCSS, className, containerEscape)}>{children}</div>
  );
};

export default NativeScroll;
