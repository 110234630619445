import { useQuery } from 'react-apollo';
import getContentItemsByTags from './Queries/GetContentItemsByTags.gql';
import getContentItemsByIds from './Queries/GetContentItemsByIds.gql';

const useGetContentManagerData = ({ ids, tags }) => {
  const getContentItemsQuery = ids
    ? getContentItemsByIds
    : tags
    ? getContentItemsByTags
    : null;
  const variables = ids ? { ids } : tags ? { tags } : null;

  const { data } = useQuery(getContentItemsQuery, {
    variables,
    skip: !ids && !tags
  });

  return data;
};

export default useGetContentManagerData;
